import { Component } from 'react';
import { NavLink } from 'react-router-dom';
// Assets
import { AiOutlineSetting, AiFillCamera, AiFillPlusCircle } from "react-icons/ai";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { BiPlusMedical } from 'react-icons/bi';
import { BsPlusLg } from 'react-icons/bs';
import { FaRegEdit } from 'react-icons/fa';
import OwnerIcon from '../../images/icon/owner-ic.png';
import AdminIcon from '../../images/icon/admin-ic.png';
import bioIcon from '../../images/icon/bio-icon.png';
import verifyIcon from '../../images/icon/verify-ic.png';
import verify from '../../images/icon/Verify.png';
import deactivateIcon from '../../images/icon/deactivate-ic.png';
import leaveIcon from '../../images/icon/leave-ic.png';
import deleteIcon from '../../images/icon/delete-ic.png';
import instructionIcon from '../../images/icon/instruction-ic.png';
import removeIc from '../../images/icon/remove-ic.png';
import DefaultImage from '../../images/image 65.jpg';
import DefaultImageLoader from '../../images/img_loader.gif';
// Style
import '../../styles/ClanView.css';
import { Modal, Dropdown } from "react-bootstrap";
// Modals
import ClanInvite from '../popup/ClanInvite';
import BioEdit from '../popup/BioEdit';
import ConfirmationModal from '../popup/ConfirmationModal';
import SingleOwnerLeave from '../popup/SingleOwnerLeave';
import CreateTeam from '../popup/CreateTeam';
import ShowMember from '../popup/ShowMember';
import Switch from "react-switch"
// Services
import ClanApis from "../../helper/clan.api";
import TeamApis from "../../helper/teams.api";
import CommonApis from '../../helper/common.api';
// Utils
import { AxiosResponse, AxiosError } from "axios";
import { connect } from 'react-redux';
import { setAllGames } from '../../redux/actions/commonActions';
import { toast } from 'material-react-toastify';
import { VerificationStatus } from '../../utils/enums.utils'
import { profile } from '../../utils/localstorage.utils';
import { ellipsis, errorHandler, defaultPreviewImage, updateLoader, getMembersIds, getAllMembersIdFromClan, getAdminData } from '../../utils/common.utils';
import ImagePreview from '../common/ImagePreview';
import NotificationsApi from '../../helper/notifications.api';
import { createAndSendNotification, removeOwnerOrAdminNotification } from '../../utils/notification.utils';
import { ASSETS, DEN } from '../../utils/Strings.utils';
import ClanDenStats from '../common/ClanDenStats';
import Image from '../common/Image';

const mapStateToProps = (props) => {
  return {
    games: props.gameData,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatchData: (data) => dispatch(setAllGames(data)),
  }
}

class ClanView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      clanView: true,
      openBioEdit: false,
      openShowMember: false,
      openConfirm: false,
      openCreateTeam: false,
      clanApi: new ClanApis(),
      clanData: {},
      members: {},
      teamApi: new TeamApis(),
      removeEmployeeId: null,
      removeEmployeeType: '',
      openSingleOwner: false,
      games: [],
      allGames: [],
      selectedGame: '',
      currentUserId: profile()?.id,
      teams: [],
      isOwner: false,
      isAdmin: false,
      showMemberType: 'array',
      allTeams: true,
      bioLimit: 200,
      previewImageDetails: {
        display: false,
        src: defaultPreviewImage
      },
      showClanDenStats: false,
      denProfile: {}
    };

    this.commonApi = new CommonApis();
    this.notificationsApi = new NotificationsApi();
    this.adminData = getAdminData();
  }

  componentDidMount() {
    this.setState({ currentUserId: profile()?.id });
    this.props.showOrHideNav(true);
    this.getInitialValues();
  }

  // To fetch initial details of selected clan
  async getInitialValues() {
    await this.getData(this.props.match.params.id)
    if (this.props?.games?.length) {
      this.setState({
        allGames: this.props.games
      });
    } else {
      await this.fetchGames()
    }
  }

  // Fetch Games 
  fetchGames() {
    updateLoader(true);
    this.state.teamApi.getGames().then((res: AxiosResponse) => {
      this.setState({
        allGames: res.data.result
      });
      updateLoader(false);
    }).catch(
      (err: AxiosError) => {
        updateLoader(false);
        errorHandler(err, this.props.logout);
      });
  }

  // Get Data From Api 
  getData(id) {
    const data = { _id: id };
    updateLoader(true);
    this.state.clanApi.getClans(data).then((res: AxiosResponse) => {
      if (!res.data.result.length) {
        this.props.history.push("/");
      } else {
        var data = res.data['result'][0]
        this.clanId = res.data['result'][0]?._id
        this.clanData = res.data['result'][0]
        this.getTeams(res.data['result'][0]?._id)
        this.setState({
          clanData: data,
          denProfile: data?.denProfile ? data.denProfile : {},
          showClanDenStats: data?.denProfile ? data?.denProfile?.isActive : false
        })
        if (data?.members?.owner?.length && data?.members?.owner.map(el => { return el?.id })?.includes(this.state.currentUserId)) {
          this.setState({ isOwner: true })
        }
        if (data?.members?.admin?.length && data?.members?.admin.map(el => { return el?.id })?.includes(this.state.currentUserId)) {
          this.setState({ isAdmin: true })
        }
        updateLoader(false);
      }
    }).catch(
      (err: AxiosError) => {
        updateLoader(false);
        errorHandler(err, this.props.logout);
      });
  }

  // Get Data From Api 
  getTeams(id, gameId?) {
    const data = { clanId: id };
    updateLoader(true);
    if (gameId) { data.gameId = gameId }
    this.state.teamApi.getTeams(data).then((res: AxiosResponse) => {
      // Set Terms & Conditions
      this.setState({
        teams: res.data.result,
      });

      // Set available game details
      if (!gameId) {
        let availableGames = [];
        res.data.result.forEach((el, i) => {
          if (i === 0) {
            availableGames.push(el.gameDetails)
          }
          if (!availableGames?.forEach((dt, i) => { return dt?._id })?.includes(el?.gameDetails?._id)) {
            availableGames.push(el.gameDetails)
          }
        });

        let jsonObject = availableGames.map(JSON.stringify);
        let uniqueGames = new Set(jsonObject);
        let uniqueGamesArray = Array.from(uniqueGames).map(JSON.parse);
        this.setState({
          games: uniqueGamesArray
        });
      }

      updateLoader(false);
    }).catch((err: AxiosError) => {
      updateLoader(false);
      errorHandler(err, this.props.logout);
    });
  }

  // get only those teams in which user is member 
  getMyTeams(gameId?) {

    const data = { clanId: this.clanId };

    if (gameId) {
      data.gameId = gameId
    }

    updateLoader(true);
    this.state.teamApi.getMyTeams(data).then((res: AxiosResponse) => {
      // Set Terms & Conditions
      this.setState({
        teams: res.data.result,
      });
      // Set available game details
      if (!gameId) {
        let availableGames = []
        res.data.result.forEach((el, i) => {
          if (i === 0) {
            availableGames.push(el.gameDetails)
          }
          if (!availableGames?.map(dt => { return dt?._id })?.includes(el?.gameDetails?._id)) {
            availableGames.push(el.gameDetails)
          }
        })
        this.setState({
          games: availableGames
        })
      }
      updateLoader(false);
    }).catch((err: AxiosError) => {
      updateLoader(false);
      errorHandler(err, this.props.logout);
    });
  }

  // Updated terms and games details on select of game 
  onSelectGame = (e) => {
    if (e.target.name) {
      this.setState({ selectedGame: e.target.name })
      let index = this.state.games.map(el => { return el.name }).indexOf(e.target.name)
      let selectedGameId = this.state.games[index]?._id
      if (this.state.allTeams) {
        this.getTeams(this.clanId, selectedGameId)
      } else {
        this.getMyTeams(selectedGameId)
      }
    } else {
      if (this.state.allTeams) {
        this.getTeams(this.clanId)
      } else {
        this.getMyTeams()
      }
    }

  }

  // After Confirmation 
  confirmed = (key) => {

    if (key === 'deactivateClan') {
      this.clanData.isActive = !this.clanData.isActive
      this.setState({
        openConfirm: false,
      });
      
      this.updateClan(this.clanData.isActive ? `Activated ${this.state?.clanData?.name} Successfully` : `Deactivated ${this.state?.clanData?.name} Successfully`, 300, (this.clanData.isActive ?'Activated' : 'Deactivated'));
  
    } else if (key === 'deleteClan') {
      this.deleteClan()
    } else if (key === 'removeOwner' || key === 'removeAdmin') {
      this.removeOwner()
    } else if (key === 'leaveClan') {
      this.leaveClanMember()
    } else if (key === 'leaveClanByTeam') {
      this.leaveTeamByTeamMember()
    }
  }

  // After Single Owner Confirmation 
  singleOwnerConfirm = (val, data?) => {
    if (val === 'delete') {
      this.deleteClan()
    } else if (val === 'deactivate') {
      this.clanData.isActive = false
      this.updateClan('Clan Deactivated')
    } else if (val === 'assign') {
      this.clanData.members = data
      this.updateClan('Owner assigned and clan left')
    }
    this.setState({ openSingleOwner: false })
  }

  // Remove Members 
  removeOwner = () => {
    if (this.state.removeEmployeeType === 'Owner') {
      const index = this.state.clanData?.members?.owner.map(el => { return el.id }).indexOf(this.state.removeEmployeeId)
      removeOwnerOrAdminNotification('clan', this.clanData, this.state.removeEmployeeId, 'Owner');
      this.clanData?.members?.owner.splice(index, 1);
      this.updateClan('Owner Removed Successfully')
    } else if (this.state.removeEmployeeType === 'Admin') {
      const index = this.state.clanData?.members?.admin.map(el => { return el.id }).indexOf(this.state.removeEmployeeId)
      removeOwnerOrAdminNotification('clan', this.clanData, this.state.removeEmployeeId, 'Admin');
      this.clanData?.members?.admin.splice(index, 1);
      this.updateClan('Admin Removed Successfully')
    }
    this.setState({
      openConfirm: false,
    });
  }

  // Delete Clan Function

  deleteClan() {
    updateLoader(true);
    this.state.clanApi.deleteClan(this.clanId).then(
      (res: AxiosResponse) => {

        this.sendNotifications('Delete');
        this.props.history.push("/clans")
        toast.success('Clan Deleted Successfully')
        this.setState({
          openConfirm: false,
        });
        
        updateLoader(false);
      }
    ).catch(
      (err: AxiosError) => {
        updateLoader(false);
        errorHandler(err, this.props.logout);
      }
    )
  }

  sendNotifications = (action) => {
    var to = getAllMembersIdFromClan(this.clanData?.members, this.state?.teams)// getMembersIds('clan', this.state?.clanData?.members)

    var data;
    switch (action) {
      case 'Delete':
        data =  {
          type : "CLAN",
          heading : "Clan Deleted 🗑️ :",
          message : `Your Clan ${this.state?.clanData?.name} has been deleted by ${profile().name} and you will no longer be able to perform any action in this Clan.`,
          link : null
        };
        break;
      case 'Activated':
        console.log("INSIDE Deactivated");
        data =  {
          type : "CLAN",
          heading : "Clan Activated !",
          message : `Your Clan ${this.state?.clanData?.name} has been activated by ${profile().name} You can create teams and Participate into world of Esportz Tournament.`,
          link : `/clan/view/${this.clanData?._id}`
        };
        break;
      case 'Deactivated':
        console.log("INSIDE Deactivated")
        data =  {
          type : "CLAN",
          heading : "Clan Deactivated !",
          message : `Your Clan ${this.state?.clanData?.name} has been deactivated by ${profile().name} and you will no longer be able to perform any action in this Clan.`,
          link : `/clan/view/${this.clanData?._id}`
        };
      break;
      case 'Removed':
      
        break;
      default:
        break;
    }
    

    createAndSendNotification(data, to);

  }

  // Update Clan Function 
  updateClan(updateMsg, type?, notificationType?) {
    updateLoader(true);
    this.clanData.members.creator = this.clanData?.members?.creator?.id;
    this.clanData.members.owner = this.clanData?.members?.owner?.map(el => { return el?.id })
    this.clanData.members.admin = this.clanData?.members?.admin?.map(el => { return el?.id })
    this.state.clanApi.updateClan(this.clanId, this.clanData).then(
      (res: AxiosResponse) => {

        this.clanData = res.data['result']
        var data = res.data['result']
        console.log("UPDATE data :: ",data);
        this.setState({
          openConfirm: false,
          clanData: data.result,
          denProfile: data.denProfile ? data.denProfile : {},
          showClanDenStats: data.denProfile?.isActive,
        });
        updateLoader(false);
        toast.success(updateMsg)
        
        if(notificationType){
          this.sendNotifications(notificationType)
        }

        if (type === 60) {
          this.getData(this.clanId)
          return false
        }
        if (type === 300) {
          this.props.history.push("/clans")
        }
        if (!res.data['result'].members?.admin?.includes(this.state.currentUserId) && !res.data['result'].members?.owner?.includes(this.state.currentUserId)) {
          this.props.history.push("/clans")
        }
       
      }
    ).catch(
      (err: AxiosError) => {
        updateLoader(false);
        errorHandler(err, this.props.logout);
      }
    )
  }

  // Remove Owner Confirm 
  removeOwnerMsg = (position, name) => {
    return (
      <p className="fs-14 fw-600 text-center text-white-50">Are you sure you want to remove {name} from the clan {position} position?</p>
    )
  }

  // Clan Deactivated confirm 
  deactivateMsg = () => {
    return (
      this.state.clanData.isActive ?
        <p className="fs-14 fw-600 text-center text-white-50">Are you sure you want to temporarily <span className="error-msg">deactivate</span> the clan?</p>
        :
        <p className="fs-14 fw-600 text-center text-white-50">Are you sure you want to activate this clan</p>
    )
  }

  // Clan delete Confirm Msg 
  clanDeleteMsg = () => {
    return (
      <p className="fs-14 fw-600 text-center text-white-50"> <span className="error-msg">Upon deletion of the clan, all the teams will get deleted and every member will be removed from the clan.<br />
        The clan will no longer exist, so no Espotz tournaments can be registered by using this clan anymore. </span> Are you sure you want to <span className="error-msg">delete</span> the clan?</p>
    )
  }

  // Leave Confirmation Msg 
  leaveClanMsg = () => {
    return (
      <p className="fs-14 fw-600 text-center error-msg">Are you sure you want to leave the current clan?</p>
    )
  }

  // Toggle Setting 
  enableSetting = () => {
    const { clanView } = this.state
    this.setState({ clanView: !clanView })
  }

  // To Open Client Invite Popup 
  onClickOpenClanInvite = () => {
    if(this.checkStatus()){
      this.setState({ openClanInvite: !this.state.openClanInvite });
    }
  }

  // To Open Create Team Modal 
  onClickOpenCreateTeam = (val) => {
    if(this.checkStatus()){
      this.setState({ openCreateTeam: !this.state.openCreateTeam });
    }
    if (val) {
      this.getTeams(this.clanId)
    }
  }

  // To Open Bio Edit Popup 
  onClickOpenBioEdit = () => {
    if(this.checkStatus()){
      this.setState({ openBioEdit: !this.state.openBioEdit });
    }
  }

  // To Open Show Member Popup 
  onClickOpenShowMember = (members?, title?) => {
    const data = {
      members: members ? members : '',
      title: title ? title : ''
    }
    this.setState({
      openShowMember: !this.state.openShowMember,
      members: data,
    })
  }

  // Taking member object and return array 
  returnArrayOfMember = (member) => {
    let memberArr = []
    if (member?.captain?.id) {
      member.captain.title = 'Captain'
    }
    if (member?.players?.length) {
      member?.players.forEach((el) => {
        if (el) {
          el.title = 'Player'
          memberArr.push(el)
        }
      })
    }
    if (member?.substitutes?.length) {
      member?.substitutes.forEach((el) => {
        if (el) {
          el.title = 'Substitute'
          memberArr.push(el)
        }
      })
    }
    if (member?.coach?.length) {
      member?.coach.forEach((el) => {
        if (el) {
          el.title = 'Coach'
          memberArr.push(el)
        }
      })
    }
    if (member?.manager?.length) {
      member?.manager.forEach((el) => {
        if (el) {
          el.title = 'Manager'
          memberArr.push(el)
        }
      })
    }
    return memberArr
  }

  // To Open Confirmation Popup 
  onClickOpenConfirm = (key?, msg?) => {
    const data = {
      msg: msg ? msg : '',
      key: key ? key : ''
    }
    if (key === 'leaveClan') {
      if (this.clanData.members?.admin && this.clanData?.members?.admin.map(el => { return el?.id })?.includes(this.state.currentUserId)) {
        this.setState({
          openConfirm: !this.state.openConfirm,
          confirm: data
        });
      } else if (this.clanData?.members?.owner?.length > 1 && this.clanData?.members?.owner.map(el => { return el?.id })?.includes(this.state.currentUserId)) {
        this.setState({
          openConfirm: !this.state.openConfirm,
          confirm: data
        });
      } else if (this.clanData?.members?.owner?.length === 1) {
        this.setState({
          openSingleOwner: !this.state.openSingleOwner
        })
      }
    } else if (key === 'removeOwner') {
      if (this.clanData?.members?.owner?.length === 1) {
        this.setState({
          openSingleOwner: !this.state.openSingleOwner
        })
      } else {
        this.setState({
          openConfirm: !this.state.openConfirm,
          confirm: data
        });
      }
    } else {
      this.setState({
        openConfirm: !this.state.openConfirm,
        confirm: data
      });
    }

  }

  // To Open Deactivate modal from Confirm Modal Popup 
  insteadDeactivate = () => {
    const data = {
      msg: this.deactivateMsg(),
      key: 'deactivateClan'
    }
    this.setState({
      confirm: data
    });
  }

  // To Send Verification Request 
  requestVerification = () => {
    this.clanData.verificationStatus = VerificationStatus.VERIFICATION_REQUESTED
    this.updateClan('Verification Request Sent')
  }

  // To Render Verification Request Status 
  renderVerification(val) {
    switch (val) {
      case VerificationStatus.VERIFIED:
        return (<>
          <button className="white-round-btn rounded-circle fw-600 border border-0 btn-size-45 mx-3" style={{ width: '100%' }}><img src={verifyIcon} style={{ height: '20px' }} alt='' /></button>
          <p className='fs-12 fw-bold text-white mt-1'>Verified</p>
        </>);
      case VerificationStatus.VERIFICATION_REQUESTED:
        return (<>
          <button className="white-round-btn rounded-circle fw-600 border border-0 btn-size-45 mx-3"><img src={verifyIcon} style={{ height: '20px' }} alt='' /></button>
          <p className='fs-12 fw-bold text-white mt-1'><span className='d-block'>Request</span> Verification</p>
        </>);
      case VerificationStatus.NOT_VERIFIED:
        return (<>
          <button disabled={!this.clanData?.isActive} className="white-round-btn rounded-circle fw-600 border border-0 btn-size-45 mx-3" onClick={this.requestVerification}><img src={verify} style={{ height: '20px' }} alt='' /></button>
          <p className='fs-12 fw-bold text-white mt-1'>Verify</p>
        </>);
      default:
        return null;
    }
  }

  // Leave clan member
  leaveClanMember = () => {
    const data = {
      clanId: this.clanId
    }
    updateLoader(true);
    this.state.clanApi.leaveClanMember(data).then(
      (res: AxiosResponse) => {
        updateLoader(false);
        toast.success('Clan left successfully')
        this.props.history.push("/clans")
      }
    ).catch(
      (err: AxiosError) => {
        updateLoader(false);
        errorHandler(err, this.props.logout);
      }
    )
  }

  // By remove team remove from clan
  leaveTeamByTeamMember = () => {
    const data = {
      clanId: this.clanId
    }
    updateLoader(true);
    this.state.clanApi.leaveTeamByTeamMember(data).then(
      (res: AxiosResponse) => {
        updateLoader(false);
        toast.success('Clan left successfully')
        this.props.history.push("/clans")
      }
    ).catch(
      (err: AxiosError) => {
        updateLoader(false);
        errorHandler(err, this.props.logout);
      }
    )
  }

  // Got to join team page
  joinTeam = (teamId, role) => {
    this.props.joinTeamAfterCreation(teamId, role, 'Team Profile');
  }

  // Preview selected image
  previewImage = (src, param) => {
    this.setState({
      previewImageDetails: {
        display: param,
        src: (param ? src : defaultPreviewImage)
      }
    });
  }

  // Set image to clan logo or cover
  addImg = (event, type) => {
    let message;
    if (type === 'logo') {
      message = 'Clan logo updated successfully'
    } else {
      message = 'Clan cover image updated successfully'
    }
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      if (!img.name.match(/\.(jpg|jpeg|png)$/)) {
        toast.warning('Please select a valid image type.');
        return false;
      }
      this.clanData[type].url = URL.createObjectURL(img)
      this.setState({ clanData: this.clanData })
      const formData = new FormData();
      formData.append('file', event.target.files[0])
      updateLoader(true);
      this.commonApi.uploadSingleImg(formData).then(
        (response: AxiosResponse) => {
          this.clanData[type].url = response.data.result.url;
          this.setState({ clanData: this.clanData })
          this.updateClan(message, 60)
          updateLoader(false);
        }).catch(
          (err: AxiosError) => {
            updateLoader(false);
            errorHandler(err, this.props.logout);
          });
    }
  };

  triggerInputFile = () => {
    if(this.checkStatus()){
      this.fileInput.click();
    }
  }

  triggerInputFile2 = () => {
    if(this.checkStatus()){
      this.fileInput2.click();
    }
  }

  updateDenStatus = () => {
    if(this.checkStatus()){
      const denProfile = this.state.denProfile;
      const publicStatus = {
        "matchPlayed": this.state?.denProfile.publicStatus?.matchPlayed ? this.state.denProfile.publicStatus.matchPlayed : true,
        "matchWin": this.state?.denProfile.publicStatus?.matchWin ? this.state.denProfile.publicStatus.matchWin : true,
        "spotlightCollection": this.state?.denProfile.publicStatus?.spotlightCollection ? this.state?.denProfile.publicStatus.spotlightCollection : true,
        "mvpCount": this.state?.denProfile.publicStatus?.mvpCount ? this.state?.denProfile.publicStatus.mvpCount : true,
        "gameStats": this.state?.denProfile.publicStatus?.gameStats ? this.state?.denProfile.publicStatus.gameStats : true,
        "bettingStatistics": this.state?.denProfile.publicStatus?.bettingStatistics ? this.state?.denProfile.publicStatus.bettingStatistics : true,
        "tournamentCount": this.state?.denProfile.publicStatus?.tournamentCount ? this.state?.denProfile.publicStatus.tournamentCount : true,
      };
      //console.log('denProfile ::::::',denProfile);
      denProfile['isActive'] = !this.state.showClanDenStats;
      denProfile['members'] = this.state.clanData?.members;
      denProfile['type'] = DEN.SEARCH.TYPES.CLAN;
      denProfile['name'] = this.state.clanData?.name;
      denProfile['bio'] = this.state.clanData?.bio;
      denProfile['logo'] = this.state.clanData?.logo;
      denProfile['coverImage'] = this.state.clanData?.coverImage;
      denProfile['clanOrOrgId'] = this.state.clanData?._id;
      denProfile['publicStatus'] = publicStatus;
      denProfile['isPublic'] = this.state.denProfile?.isPublic ? denProfile.isPublic : false;
      denProfile['socialMedia'] = this.state.denProfile?.socialMedia ? denProfile.socialMedia : [];
      this.setState({
        showClanDenStats : !this.state.showClanDenStats, 
        denProfile: denProfile
      });
      let msg;
      if(this.state.denProfile.isActive){
        msg='Clan den profile activated successfully';
      }else{
        msg='Clan den profile deactivated successfully';
      }
      this.updateDenProfile(msg);
    }
  }

  checkStatus = () => { 
    if(profile()?.block){
      toast.warning('You cannot perform this action as you are blocked by Espotz Admin');
      return false;
    }
    if (!this.state.clanData?.isActive) {
      toast.warning('Please activate the clan to perform this action');
      return false;
    }
    if(!(this.state.isAdmin || this.state.isOwner)){
      toast.warning('Only Clan owner/admin can perform this action');
      return false;
    }
    return true;
  }

  checkTeamStatus = (el) => { 
    if(el.block){
      toast.warning('This team is currently blocked by Espotz Admin');
      return false;
    }
    this.props.history.push(`/clan/team/view/${el._id}`);
  }

  updateDenProfile = (msg) => {
    updateLoader(true);
    const data = { 
      denData : this.state.denProfile
    }
    this.state.clanApi.updateClan(this.clanId, data).then(
      (res: AxiosResponse) => {
        this.clanData = res.data.result;
        var data = res.data.result;
        console.log('update clan denProfile ::', data);
        this.setState({
          openConfirm: false,
          clanView: false,
          clanData: data,
          denProfile: data.denProfile,
          showClanDenStats: data.denProfile?.isActive,
        });
        updateLoader(false);
        toast.success(msg);
      }
    ).catch(
      (err: AxiosError) => {
        updateLoader(false);
        errorHandler(err, this.props.logout);
      }
    )
  }

  setDenData = (data, msg) => {
    this.setState({denProfile: data});
    this.updateDenProfile(msg);
  }

  redirectToDen = () => { 
    if(this.checkStatus()){
        if(!this.state.denProfile){
            toast.warning('This organization does not have a den Profile yet.');
            return false;
        }
        this.props.history.push(`/den/${this.state.denProfile?._id}`);
    }
}

  render() {
    const { openClanInvite, openBioEdit, openShowMember, openConfirm, openCreateTeam, confirm, members, openSingleOwner } = this.state;

    return (
      <div>

        {/* Clan View */}
        <section className="body-section clan-view-page">
          <div className="clanView-banner">
            <img className="clanView-background" src={this.state.clanData?.coverImage?.url ? this.state?.clanData?.coverImage?.url : DefaultImageLoader} width="50" height="50" alt="" />
            <img className="clanView-fronImage"
              src={this.state.clanData?.coverImage?.url ? this.state?.clanData?.coverImage?.url : DefaultImageLoader}
              alt=""
              onClick={() => this.previewImage((this.state.clanData?.coverImage?.url ? this.state?.clanData?.coverImage?.url : DefaultImageLoader), true)}
              width="50"
              height="50" />
            {(this.state.clanView) ?
              null :
              <div className='cover-cam-container bg-white position-absolute btn-size-25 rounded-circle d-flex align-items-center justify-content-center'>
                <input type="file" id="upload_file" accept=".png, .jpg, .jpeg" hidden onChange={(e) => this.addImg(e, 'coverImage')} ref={fileInput2 => this.fileInput2 = fileInput2} name="" className="profile-picker" />
                <AiFillCamera className="text-dark" id="upload_file" onClick={this.triggerInputFile2} />
              </div>
            }
          </div>

          {this.state.clanView ?

            <div className="container ClanView-section ps-4 pe-4">
              <NavLink to="/clans">
                <IoArrowBackCircleOutline className="back-btn" />
              </NavLink>

              <div className="row d-flex flex-row position-relative" style={{ alignItems: 'flex-end', marginTop: '-100px' }}>
                <div className="col-lg-2 col-3">
                  <img
                    src={this.state.clanData?.logo?.url ? this.state?.clanData?.logo?.url : DefaultImageLoader}
                    onClick={() => this.previewImage((this.state.clanData?.logo?.url ? this.state?.clanData?.logo?.url : DefaultImageLoader), true)}
                    alt="clan logo"
                    className="organization-logo rounded-10 position-relative" />
                </div>

                <div className="col-lg-9 col-7">
                  <h6 className="fs-20 fs-lg-24 fw-600 text-white mb-0 ms-4 text-truncate">
                    <span className='fs-12 fs-lg-16 fw-600 text-white-50'>Welcome to </span> <br />
                    {this.state?.clanData?.name?.substring(0, 25)}{(this.state?.clanData?.name?.length > 25) ? '...' : null}
                    {/* <marquee className='marquee-text fs-12 fs-lg-16 fw-600 text-white-50' direction="left">{this.state?.clanData?.name}<span className='text-warning px-4'>$$$</span> {this.state?.clanData?.name}</marquee> */}
                  </h6>
                </div>
                <div className='col-lg-1 col-2'>
                  <button type="button" className="white-round-btn rounded-circle fw-600 border border-0 btn-size-32 float-end" data-dismiss="modal" aria-label="Close" onClick={this.enableSetting}>
                    <AiOutlineSetting className="btn-size-20" />
                  </button>
                </div>
              </div>

              <span className='d-flex align-items-center mt-3'>
                <img src={bioIcon} className="" alt='' style={{ width: '20px' }} />
                <p className='fs-14 fw-600 ms-2 mt-1 mb-0 text-white'>Bio</p>
              </span>
              <p className="fs-14 fs-lg-18 fw-normal text-white-50" style={{ textAlign: 'justify' }}>{this.state?.clanData?.bio?.substring(0, this.state.bioLimit)} {(this.state?.clanData?.bio?.length > 200) ? (this.state.bioLimit === 200 ? <a className="fs-14 fw-normal" onClick={() => { this.setState({ bioLimit: 500 }) }}>show More</a> : <a className="fs-14 fw-normal" onClick={() => { this.setState({ bioLimit: 200 }) }}>show Less</a>) : null}</p>


              <div className="row my-3">
                <div className="col-lg-2 col-md-4 col-6">
                  <h6 className='fs-16 fw-600 text-white member-type'><img src={OwnerIcon} className="" alt="owner icon" style={{ width: '20px', height: '25px' }} /> Clan Owners</h6>
                  {
                    (this.state?.clanData?.members?.owner && this.state?.clanData?.members?.owner?.length) ?
                      <div className='d-flex justify-content-between'>
                        <div className="avatars ms-3" onClick={() => { this.onClickOpenShowMember(this.state?.clanData?.members?.owner, 'Clan Owner'); }}>
                          {(this.state?.clanData?.members?.owner && this.state?.clanData?.members?.owner?.length) ?
                            this.state?.clanData?.members?.owner.map((el, i) => {
                              if ((el !== null) && (i < 4)) {
                                return (
                                  <span className="avatar" key={i}>
                                    {/* <img className='btn-size-32 border border-1' src={el?.profilePicture?.url ? el?.profilePicture?.url : DefaultImage} width="50" height="50" alt="profile" /> */}
                                    <Image src={el?.profilePicture?.url} param={el.username} className='btn-size-32 border border-1' height='50px' width='50px' />
                                  </span>
                                );
                              }
                              return false;
                            }) : ''}
                          {(this.state?.clanData?.members?.owner && this.state?.clanData?.members?.owner?.length > 4) ?
                            <span className="avatar d-inline-flex">
                              <p className='fs-8 fw-500 rounded-circle position-relative text-center mb-0 text-white btn-size-32 d-flex justify-content-center align-items-center'>+{this.state?.clanData?.members?.owner?.length - 4}</p>
                            </span>
                            : null}
                        </div>
                        <div>
                          {
                            (this.state.isAdmin || this.state.isOwner) &&
                            <button type="button" className='white-round-btn rounded-circle fw-600 border border-0 btn-size-32' onClick={this.onClickOpenClanInvite}>
                              <BiPlusMedical className='text-black' />
                            </button>

                          }
                        </div>
                      </div>
                      :
                      <div>
                        {
                          (this.state.isAdmin || this.state.isOwner) &&
                          <p disabled={!this.clanData?.isActive} className="black-flat-btn p-1 ms-4 w-75 text-center mb-0 member-type-btn rounded-10" onClick={this.onClickOpenClanInvite}>
                            <AiFillPlusCircle className='' />
                          </p>
                        }
                      </div>
                  }
                </div>

                <div className="col-lg-2 col-md-4 col-6">
                  <h6 className='fs-16 fw-600 text-white member-type'><img src={AdminIcon} className="" alt="admin icon" style={{ width: '20px', height: '25px' }} /> Clan Admins</h6>
                  {
                    (this.state?.clanData?.members?.admin && this.state?.clanData?.members?.admin?.length) ?
                      <div className='d-flex justify-content-between'>
                        <div className="avatars ms-3" onClick={() => { this.onClickOpenShowMember(this.state?.clanData?.members?.admin, 'Clan Admin') }}>
                          {
                            (this.state?.clanData?.members?.admin && this.state?.clanData?.members?.admin?.length) ?
                              this.state?.clanData?.members?.admin?.map((el, i) => {
                                if ((el !== null) && (i < 4)) {
                                  return (
                                    <span className="avatar" key={i}>
                                      {/* <img className='btn-size-32 border border-1' src={el?.profilePicture?.url ? el?.profilePicture?.url : DefaultImage} alt="profile-logo" width="50" height="50" /> */}
                                      <Image src={el?.profilePicture?.url} param={el.username} className='btn-size-32 border border-1' height='50px' width='50px' />
                                    </span>
                                  )
                                }
                                return false
                              }) : ''
                          }
                          {
                            (this.state?.clanData?.members?.admin && this.state?.clanData?.members?.admin?.length > 4) ?
                              <span className="avatar d-inline-flex">
                                <p className='fs-8 fw-500 rounded-circle position-relative text-center mb-0 text-white btn-size-32 d-flex justify-content-center align-items-center'>+{this.state?.clanData?.members?.admin?.length - 4}</p>
                              </span>
                              : null
                          }
                        </div>
                        <div>
                          {
                            (this.state.isAdmin || this.state.isOwner) &&
                            <button type="button" className='white-round-btn rounded-circle fw-600 border border-0 btn-size-32' onClick={this.onClickOpenClanInvite}>
                              <BiPlusMedical className='text-black' />
                            </button>

                          }
                        </div>
                      </div>
                      :
                      <div>
                        {
                          (this.state.isAdmin || this.state.isOwner) &&
                          <p disabled={!this.clanData?.isActive} className="black-flat-btn p-1 ms-4 w-75 text-center mb-0 member-type-btn rounded-10" onClick={this.onClickOpenClanInvite}>
                            <AiFillPlusCircle className='fs-20 my-1' />
                          </p>
                        }
                      </div>
                  }
                </div>

                <div className="row my-3 d-flex justify-content-center">
                  {/* Redirect to Tournaments */}
                  <div className="col-12 col-md-4">
                  <NavLink to={{
                      pathname: `/tournaments/my-tournaments`,
                      state: {
                        fromClan: true,
                        clanId: this.state.clanData?._id
                      }
                  }}>
                      <button className='pink-skyblue-gradient-btn w-100 fw-bold py-3 m-auto mb-2 text-white border-0'>Tournaments</button>
                    </NavLink>
                  </div>
                  {/* Redirect to Clan's Den Page */}
                  <div className={`${(this.state.denProfile && this.state.denProfile.isActive) ? 'col-12 col-md-4' : 'd-none'}`}>
                    <button className='pink-skyblue-gradient-btn flat-btn w-100 fw-bold py-3 m-auto mb-2 text-white border-0' onClick={this.redirectToDen}>Den</button>
                  </div>
                  <NavLink to={`/clan/wallet/${this.state?.clanData?._id}`} className="col-12 col-md-4">
                    <button className='pink-skyblue-gradient-btn flat-btn wallet-btn w-100 fw-bold py-3 m-auto mb-2 text-white border-0'>Wallet</button>
                  </NavLink>
                </div>

              </div>

              <div className="row">

                <div className='d-flex justify-content-between my-3'>
                  <div>
                    <button className='white-flat-btn fs-12 fw-bold text-black px-2 px-md-4 px-lg-4 py-2 py-md-2 py-lg-2 m-auto mb-2 border-0 me-1 team-toggle' disabled={!this.clanData?.isActive} onClick={() => { this.getTeams(this.clanId); this.setState({ allTeams: true, selectedGame: '' }) }}>All Teams</button>
                    <button className='white-flat-btn fs-12 fw-bold text-black px-2 px-md-4 px-lg-4 py-2 py-md-2 py-lg-2 m-auto mb-2 border-0 ms-1 team-toggle' disabled={!this.clanData?.isActive} onClick={() => { this.getMyTeams(); this.setState({ allTeams: false, selectedGame: '' }) }} >Your Teams</button>
                  </div>
                  <div>
                    <Dropdown onClick={() => { }} className="mb-2 select-game-dropdown">
                      <Dropdown.Toggle disabled={!this.clanData?.isActive} className='dropdown-basic fs-12 fw-bold px-2 px-md-4 px-lg-4 py-2 py-md-2 py-lg-2 game-fs team-dropdown-arrow'>
                        {
                          this.state.selectedGame ? ellipsis(this.state.selectedGame, 20) : 'Select Game'
                        }

                      </Dropdown.Toggle>
                      {this.state.games?.length ?
                        <Dropdown.Menu onClick={(e) => this.onSelectGame(e)}>
                          {
                            (this.state.games?.length > 1) && <Dropdown.Item href="#" name='' className='text-white'>All Games</Dropdown.Item>
                          }
                          {
                            this.state.games?.map((el, i) => {
                              return (
                                <Dropdown.Item key={i} href="#" name={el.name} className='text-white'>{ellipsis(el.name, 25)}</Dropdown.Item>
                              )
                            })
                          }
                        </Dropdown.Menu>
                        : null
                      }
                    </Dropdown>
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="tab-content">
                    <div className="tab-pane active" id="prices">
                      <div className="row">
                        {
                          this.state.teams?.length ?
                            this.state.teams.map((el, i) => {
                              return (
                                <div key={i} className="col-lg-4 col-md-6 col-sm-10 mb-3 text-center">
                                  <div className="epz-team-members" onClick={() => { this.onClickOpenShowMember(this.returnArrayOfMember(el?.members), 'Team Members') }}>
                                    {
                                      this.returnArrayOfMember(el?.members).map((el, i) => {
                                        if (i < 4) {
                                          return (
                                            <span key={i} className="avatar">
                                              {/* <img src={el?.profilePicture?.url ? el.profilePicture.url : DefaultImage} width="50" height="50" alt="profile" className='border border-1 border-dark' /> */}
                                              <Image src={el?.profilePicture?.url} param={el.inGameName} className='border border-1 border-dark' height='50px' width='50px' />
                                            </span>
                                          )
                                        }
                                      })
                                    }
                                    {
                                      (this.returnArrayOfMember(el?.members).length > 4) ?
                                        <span className="avatar d-inline-flex">
                                          <p className='fs-8 fw-500 rounded-circle position-relative text-center mb-0 text-white btn-size-25 d-flex justify-content-center align-items-center'>+{this.returnArrayOfMember(el?.members).length - 4}</p>
                                        </span>
                                        : null
                                    }
                                  </div> 
                                  <div onClick={() => this.checkTeamStatus(el)}>
                                    <div className="monochrome-card">
                                      <div className="card-body">
                                        <div className="row">
                                          <div className="col-3 d-block align-self-center">
                                            <div className="rounded-circle " style={{ backgroundImage: `url(${el?.logo?.url ? el.logo?.url : DefaultImage})`, width: '60px', height: '60px', backgroundSize: 'cover' }}></div>
                                          </div>
                                          <div className="col-5 text-start mt-1">
                                            <p className="fs-16 fw-600 text-white text-truncate">{el.name}</p>
                                          </div>
                                          <div className="col-4 mt-1">
                                            <p className="fs-14 fw-bold text-warning mb-0">Game</p>
                                            <p className="fs-16 fw-500 text-white-50 mb-0 text-truncate">{el.gameDetails.name}</p>
                                          </div>
                                        </div>

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )
                            })
                            :
                            <div className="text-center">
                                <img className="no-tour-found" src={ASSETS.NO_TEAMS} alt={""} />
                            </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {
                (this.state.isAdmin || this.state.isOwner) &&
                <div className='d-flex justify-content-end'>
                  <button type="button" className='creat-team-btn white-round-btn rounded-circle fw-600 border border-0 btn-size-40 position-fixed' style={{ bottom: '60px' }} onClick={() => { this.onClickOpenCreateTeam(null) }}>
                    <BsPlusLg className='text-info' />
                  </button>
                </div>
              }

            </div>

            :

            <div className="container setting-section ps-4 pe-4">
              <IoArrowBackCircleOutline className="back-btn" onClick={this.enableSetting} />

              <div className="row d-flex flex-row position-relative" style={{ alignItems: 'flex-end', marginTop: '-100px' }}>
                <div className="col-lg-2 col-3">
                  <img
                    src={this.state.clanData?.logo?.url ? this.state?.clanData?.logo?.url : DefaultImageLoader}
                    onClick={() => this.previewImage((this.state.clanData?.logo?.url ? this.state?.clanData?.logo?.url : DefaultImageLoader), true)}
                    alt="clan logo"
                    className="organization-logo rounded-10 position-relative" />
                  <input type="file" id="upload_file" accept=".png, .jpg, .jpeg" hidden onChange={(e) => this.addImg(e, 'logo')} ref={fileInput => this.fileInput = fileInput} name="" className="profile-picker" />
                  <div className='cam-container bg-white position-absolute btn-size-25 rounded-circle d-flex align-items-center justify-content-center'>
                    <AiFillCamera className="text-dark" id="upload_file" onClick={this.triggerInputFile} />
                  </div>
                </div>

                <div className="col-lg-9 col-7">
                  <h6 className="fs-20 fs-lg-24 fw-600 text-white mb-0 ms-4 text-truncate">
                    <span className='fs-12 fs-lg-16 fw-600 text-white-50'>Welcome to </span> <br />
                    {this.state?.clanData?.name?.substring(0, 25)}{(this.state?.clanData?.name?.length > 25) ? '...' : null}
                  </h6>
                </div>
                <div className='col-lg-1 col-2'>
                  <button type="button" className="white-round-btn rounded-circle fw-600 border border-0 btn-size-32 float-end" data-dismiss="modal" aria-label="Close" onClick={this.enableSetting}>
                    <AiOutlineSetting className="btn-size-20" />
                  </button>
                </div>
              </div>

              <div className='d-flex justify-content-between mt-3'>
                <span className='d-flex align-items-center mb-1'>
                  <img src={bioIcon} className="mb-0" alt='' style={{ width: '20px' }} />
                  <p className='fs-14 fw-600 ms-2 mt-1 mb-0 text-white'>Bio</p>
                </span>
                {
                  (this.state.isAdmin || this.state.isOwner) &&
                  <button className="float-end" style={{ background: 'transparent', border: 'none' }} onClick={this.onClickOpenBioEdit}>
                    <FaRegEdit className="text-white fs-20" />
                  </button>
                }
              </div>

              <p className="martinique-card fs-12 fs-lg-14 fw-600 p-3 mt-1" style={{ textAlign: 'justify' }}>{this.state?.clanData?.bio}</p>

              <div className="col-12 mt-3" >
                <p className="fs-16 fw-600 text-white text-center mb-1"> <img src={OwnerIcon} alt='clan owner' style={{ width: '20px', height: '25px' }} /> Clan Owners</p>
                <div className='row text-center justify-content-center'>
                  {
                    this.state.clanData?.members?.owner?.length ?
                      this.state.clanData?.members.owner.map((el, i) => {
                        return (
                          <div className="col-12 col-md-6 col-lg-4 mt-2" key={i} >
                            <div className="mulberry-purple-card d-flex align-items-center py-1">
                              <div className="col-3">
                                {/* <img className="rounded-circle btn-size-32 border border-1 my-1" src={el?.profilePicture?.url ? el?.profilePicture?.url : DefaultImage} alt="owner-avatar" /> */}
                                <Image src={el?.profilePicture?.url} param={el.username} className='rounded-circle btn-size-32 border border-1 my-1' />
                              </div>
                              <div className="col-7">
                                <div className="fs-16 fw-500 text-white text-start text-truncate">{el?.username}</div>
                              </div>
                              <div className="col-2">
                                {this.state.isOwner ?
                                  <button disabled={!this.clanData?.isActive} type="button" className="border border-0 d-flex align-items-center bg-transparent" data-dismiss="modal" aria-label="Close" onClick={() => { this.onClickOpenConfirm('removeOwner', this.removeOwnerMsg('Owners', el?.name)); this.setState({ removeEmployeeId: el?.id, removeEmployeeType: 'Owner' }) }}>
                                    <img src={removeIc} className='btn-size-40' alt='' />
                                  </button>
                                  : null}
                              </div>
                            </div>
                          </div>
                        )
                      })
                      : <p className='fs-12 fw-500 text-white my-1'>No Owners present</p>
                  }
                </div>
              </div>


              <div className="col-12 mt-3" >
                <p className="fs-16 fw-600 text-white text-center mb-2"> <img src={AdminIcon} alt='Admin Icon' style={{ width: '20px', height: '25px' }} /> Clan Admins</p>
                <div className="row text-center justify-content-center">
                  {
                    (this.state.clanData?.members?.admin && this.state.clanData?.members?.admin?.length) ?
                      this.state.clanData?.members?.admin.map((el, i) => {
                        return (
                          <div className="col-12 col-md-6 col-lg-4 mt-2">
                            <div className="mulberry-purple-card d-flex align-items-center py-1">
                              <div className="col-3">
                                {/* <img className="rounded-circle btn-size-32 border border-1 my-1" src={el?.profilePicture?.url ? el?.profilePicture?.url : DefaultImage} alt="owner-avatar" /> */}
                                <Image src={el?.profilePicture?.url} param={el.username} className='rounded-circle btn-size-32 border border-1 my-1' />
                              </div>
                              <div className="col-7">
                                <div className="fs-16 fw-500 text-white text-start text-truncate">{el?.username}</div>
                              </div>
                              <div className="col-2">
                                {(this.state.isOwner || this.state.isAdmin) ?
                                  <button disabled={!this.clanData?.isActive} type="button" className="border border-0 d-flex align-items-center bg-transparent" data-dismiss="modal" aria-label="Close" onClick={() => { this.onClickOpenConfirm('removeAdmin', this.removeOwnerMsg('Admin', el?.name)); this.setState({ removeEmployeeId: el?.id, removeEmployeeType: 'Admin' }) }}>
                                    <img src={removeIc} className='btn-size-40' alt='' />
                                  </button>
                                  : null}
                              </div>
                            </div>
                          </div>
                        )
                      }) : <p className='fs-12 fw-500 text-white my-1'>No Admins present</p>
                  }

                </div>
                {/* SHOW FOR OWNER AND ADMIN */}
                <div className={`${ (this.state.isAdmin || this.state.isOwner) ? 'd-block' : 'd-none' }`}>
                  <div className="catalina-blue my-3 height-45 d-flex align-items-center">
                      <p className="fs-12 fw-600 mb-0 flex-grow-1 ps-3">Den Profile</p>
                      <Switch checked={this.state.showClanDenStats} checkedIcon={false} onChange={this.updateDenStatus} uncheckedIcon={false} className="me-2" />
                  </div>

                  <div className={`${this.state.showClanDenStats ? 'd-block' : 'd-none'}`}>
                    <ClanDenStats denProfile={this.state.denProfile} setDenData={this.setDenData} />
                  </div>
                </div>

                <p className='fs-16 fw-700'>Control Center<span className='mx-2 fw-500'>(Clan)</span></p>

                <div className='col-12 col-lg-6 offset-lg-3 d-flex justify-content-around my-3 text-center'>
                  <div className=''>
                    {
                      (this.state.isAdmin || this.state.isOwner) &&
                      this.renderVerification(this.state?.clanData?.verificationStatus)
                    }
                  </div>
                  <div>
                    {this.state.isOwner ?
                      <>
                        <button className="white-round-btn rounded-circle fw-600 border border-0 btn-size-45 mx-3" onClick={() => { this.onClickOpenConfirm('deactivateClan', this.deactivateMsg()); }}>
                          <img src={deactivateIcon} style={{ height: '20px' }} alt='' />
                        </button>
                        <p className='fs-12 fw-bold text-white mt-1'>{this.clanData?.isActive ? 'Deactivate' : 'Respawn'}</p>
                      </>
                      : null}
                  </div>
                  <div>
                    {
                      (this.state.isAdmin || this.state.isOwner) ?
                        <>
                          <button disabled={!this.clanData?.isActive && this.state.isOwner} className="white-round-btn rounded-circle fw-600 border border-0 btn-size-45 mx-3" onClick={() => { this.onClickOpenConfirm('leaveClan', this.leaveClanMsg()); }}>
                            <img src={leaveIcon} style={{ height: '20px' }} alt='' />
                          </button>
                          <p className='fs-12 fw-bold text-white mt-1'>Leave Clan</p>
                        </>
                        :
                        !this.clanData?.isActive ?
                          <>
                            <button className="white-round-btn rounded-circle fw-600 border border-0 btn-size-45 mx-3" onClick={() => { this.onClickOpenConfirm('leaveClanByTeam', this.leaveClanMsg()); }}>
                              <img src={leaveIcon} style={{ height: '20px' }} alt='' />
                            </button>
                            <p className='fs-12 fw-bold text-white mt-1'>Leave Clan</p>
                          </> : null
                    }
                  </div>
                  <div>
                    {this.state.isOwner ?
                      <>
                        <button className="white-round-btn rounded-circle fw-600 border border-0 btn-size-45 mx-3" onClick={() => { this.onClickOpenConfirm('deleteClan', this.clanDeleteMsg()); }}>
                          <img src={deleteIcon} style={{ height: '20px' }} alt='' />
                        </button>
                        <p className='fs-12 fw-bold text-white mt-1'>Delete Clan</p>
                      </>
                      : null}
                  </div>
                </div>
                <div className='col-12'>
                  <div className='text-center'>
                    <img src={instructionIcon} className='' height={'40px'} alt='' />
                  </div>
                  <div className='mt-3'>
                    <ol className='ps-0'>
                      <li className='fs-12 fs-lg-16 fw-500 text-start'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                      <li className='fs-12 fs-lg-16 fw-500 text-start'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                      <li className='fs-12 fs-lg-16 fw-500 text-start'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                      <li className='fs-12 fs-lg-16 fw-500 text-start'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                      <li className='fs-12 fs-lg-16 fw-500 text-start'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          }

        </section>

        {/* Join clan invite modal */}
        <Modal show={openClanInvite} centered dialogClassName="clan-invite-modal">
          <Modal.Body>
            <ClanInvite openClanInvite={openClanInvite} clanId={this.clanId} name={this.state.clanData?.name}
              onClick={this.onClickOpenClanInvite} inviteType='clan' isOwner={this.state.isOwner}
            />
          </Modal.Body>
        </Modal>

        {/* Create new team modal */}
        <Modal show={openCreateTeam} centered dialogClassName="create-team-modal">
          <Modal.Body>
            <CreateTeam openCreateTeam={openCreateTeam} games={this.state.allGames}
              onClickOpenCreateTeam={this.onClickOpenCreateTeam} clan={this.state.clanData}
              updateLoader={this.props.updateLoader}
              joinTeam={this.joinTeam}
            />
          </Modal.Body>
        </Modal>

        {/* Edit bio details modal */}
        <Modal show={openBioEdit} centered dialogClassName="BioEdit-modal">
          <Modal.Body>
            <BioEdit openBioEdit={openBioEdit}
              onClickOpenBioEdit={this.onClickOpenBioEdit} clanData={this.state?.clanData}
              bioChange={() => { this.getData(this.clanId) }}
              updateLoader={this.props.updateLoader} />
          </Modal.Body>
        </Modal>

        {/* Confirmation modal */}
        <Modal show={openConfirm} centered dialogClassName="Confirmation-modal">
          <Modal.Body>
            <ConfirmationModal openConfirm={openConfirm} confirm={confirm} clanStatus={this.state.clanData?.isActive}
              onClickOpenConfirm={this.onClickOpenConfirm} confirmed={this.confirmed} insteadDeactivate={this.insteadDeactivate}
            />
          </Modal.Body>
        </Modal>

        {/* Display selected clan members details modal */}
        <Modal show={openShowMember} centered dialogClassName="ShowMember-modal">
          <Modal.Body>
            <ShowMember openShowMember={openShowMember} members={members}
              onClickOpenShowMember={this.onClickOpenShowMember} />
          </Modal.Body>
        </Modal>

        {/* Leave clan confirmation modal */}
        <Modal show={openSingleOwner} centered dialogClassName="Confirmation-modal">
          <Modal.Body>
            <SingleOwnerLeave members={this.state.clanData?.members}
              onClick={this.singleOwnerConfirm} type="clan" />
          </Modal.Body>
        </Modal>

        {/* Image preview modal */}
        <Modal show={this.state.previewImageDetails.display} centered dialogClassName="image-preview-container bg-transparent">
          <ImagePreview previewImageDetails={this.state.previewImageDetails} previewImage={this.previewImage} />
        </Modal>

      </div>
    );
  }
}

export default (connect(mapStateToProps, mapDispatchToProps))(ClanView)