export const getTeams = [
    {
        "_id": "62e135bedee2e3001371f54d",
        "clanId": "62e11d8cdee2e3001371c095",
        "gameId": "624fb8520318477ac01de652",
        "name": "BGMI ONE",
        "isClanLogo": false,
        "logo": {
            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/c379ebc0-0d9c-11ed-8be9-277906d0995e",
            "name": "file",
            "size": 1231917,
            "type": "image/png"
        },
        "members": {
            "players": [
                {
                    "_id": "62e135cbdee2e3001371f55c",
                    "email": "sharief.shaik@virtoustack.com",
                    "isDeleted": false,
                    "profilePicture": {
                        "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/35696ec0-408a-11ed-bafc-47de7fa3dcee",
                        "name": "file",
                        "size": 11300,
                        "type": "image/jpeg"
                    },
                    "name": "Nawaz",
                    "createdAt": "2022-07-27T12:55:39.335Z",
                    "updatedAt": "2022-07-27T12:55:39.335Z",
                    "__v": 0,
                    "countryCode": {
                        "name": "India",
                        "code": "+91",
                        "abbreviation": "IN",
                        "number": "0987654321"
                    },
                    "discordProfile": {
                        "id": "747408958616240130",
                        "discordUsername": "Nawaz#7947",
                        "name": "Nawaz",
                        "verified": true,
                        "email": "shariefnawaz1995@gmail.com",
                        "profilePicture": null
                    },
                    "kycVerified": false,
                    "den": "6322f4747c0961695c5cd480",
                    "isVerified": "NOT_VERIFIED",
                    "verificationStatus": "VERIFIED",
                    "block": false,
                    "customFields": [],
                    "mobileNo": {
                        "code": "+91",
                        "number": 9784563120
                    },
                    "inGameId": "231456789897645312",
                    "inGameName": "Nawazz",
                    "inDiscordId": "Nawaz#7947",
                    "tournamentId": null,
                    "teamId": "62e135bedee2e3001371f54d",
                    "role": "players",
                    "id": "61a4bf76cbec4b0012cb4336"
                }
            ],
            "captain": {
                "_id": "62e135cbdee2e3001371f55c",
                "email": "sharief.shaik@virtoustack.com",
                "isDeleted": false,
                "profilePicture": {
                    "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/35696ec0-408a-11ed-bafc-47de7fa3dcee",
                    "name": "file",
                    "size": 11300,
                    "type": "image/jpeg"
                },
                "name": "Nawaz",
                "createdAt": "2022-07-27T12:55:39.335Z",
                "updatedAt": "2022-07-27T12:55:39.335Z",
                "__v": 0,
                "countryCode": {
                    "name": "India",
                    "code": "+91",
                    "abbreviation": "IN",
                    "number": "0987654321"
                },
                "discordProfile": {
                    "id": "747408958616240130",
                    "discordUsername": "Nawaz#7947",
                    "name": "Nawaz",
                    "verified": true,
                    "email": "shariefnawaz1995@gmail.com",
                    "profilePicture": null
                },
                "kycVerified": false,
                "den": "6322f4747c0961695c5cd480",
                "isVerified": "NOT_VERIFIED",
                "verificationStatus": "VERIFIED",
                "block": false,
                "customFields": [],
                "mobileNo": {
                    "code": "+91",
                    "number": 9784563120
                },
                "inGameId": "231456789897645312",
                "inGameName": "Nawazz",
                "inDiscordId": "Nawaz#7947",
                "tournamentId": null,
                "teamId": "62e135bedee2e3001371f54d",
                "role": "players",
                "id": "61a4bf76cbec4b0012cb4336"
            },
            "coach": [
                {
                    "_id": "633c0a8aed2c7100142e4b4c",
                    "email": "sharief.shaik@virtoustack.com",
                    "isDeleted": false,
                    "profilePicture": {
                        "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/35696ec0-408a-11ed-bafc-47de7fa3dcee",
                        "name": "file",
                        "size": 11300,
                        "type": "image/jpeg"
                    },
                    "name": "Naruto",
                    "createdAt": "2022-10-04T10:27:22.850Z",
                    "updatedAt": "2022-10-04T10:27:22.850Z",
                    "__v": 0,
                    "countryCode": {
                        "name": "India",
                        "code": "+91",
                        "abbreviation": "IN",
                        "number": "0987654321"
                    },
                    "discordProfile": {
                        "id": "747408958616240130",
                        "discordUsername": "Nawaz#7947",
                        "name": "Nawaz",
                        "verified": true,
                        "email": "shariefnawaz1995@gmail.com",
                        "profilePicture": null
                    },
                    "kycVerified": false,
                    "den": "6322f4747c0961695c5cd480",
                    "isVerified": "NOT_VERIFIED",
                    "verificationStatus": "VERIFIED",
                    "block": false,
                    "customFields": [],
                    "teamId": "62e135bedee2e3001371f54d",
                    "inGameName": "Nawaz",
                    "role": "coach",
                    "id": "61a4bf76cbec4b0012cb4336"
                }
            ],
            "manager": [
                {
                    "_id": "6316e4d11f0b7e0013f726c1",
                    "countryCode": {
                        "name": "India",
                        "code": "+91",
                        "abbreviation": "IN",
                        "number": 987654321
                    },
                    "email": "prajakta.ganakwar@virtoustack.com",
                    "isDeleted": false,
                    "profilePicture": {
                        "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/6b933660-ee10-11ec-ad22-011eb7b9b560",
                        "name": "file",
                        "size": 697109,
                        "type": "image/jpeg"
                    },
                    "name": "Aqua",
                    "createdAt": "2022-09-06T06:12:33.705Z",
                    "updatedAt": "2022-09-06T06:12:33.705Z",
                    "__v": 0,
                    "discordProfile": {
                        "id": "913766931125915649",
                        "discordUsername": "Praj26#9800",
                        "name": "Praj26",
                        "verified": true,
                        "email": "prajakta.ganakwar@virtoustack.com",
                        "profilePicture": null
                    },
                    "kycVerified": false,
                    "den": "6347b5168e618427127da1ef",
                    "isVerified": "false",
                    "verificationStatus": "NOT_VERIFIED",
                    "customFields": [],
                    "teamId": "62e135bedee2e3001371f54d",
                    "inGameName": "Espotz Username",
                    "role": "manager",
                    "id": "62986f285b4a1d0014af79cb"
                }
            ],
            "substitutes": [
                {
                    "_id": "6316ea2c1f0b7e0013f73b76",
                    "name": "Full name",
                    "email": "ganakwar.praj03@gmail.com",
                    "profilePicture": {
                        "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/30eb7ac0-d5ad-11ec-9605-7faa4d3dbab8",
                        "name": "file",
                        "size": 72910,
                        "type": "image/jpeg"
                    },
                    "is18AndAbove": true,
                    "isDeleted": false,
                    "createdAt": "2022-09-06T06:35:24.900Z",
                    "updatedAt": "2022-09-06T06:35:24.900Z",
                    "__v": 0,
                    "countryCode": {
                        "name": "India",
                        "code": "+91",
                        "abbreviation": "IN",
                        "number": 987654321
                    },
                    "discordProfile": {
                        "id": "943001393810247740",
                        "discordUsername": "Praj261#6136",
                        "name": "Praj261",
                        "verified": true,
                        "email": "prajakta.tmp@gmail.com",
                        "profilePicture": null
                    },
                    "kycVerified": false,
                    "den": "6347b36e8e618427127da1ee",
                    "isVerified": false,
                    "verificationStatus": "NOT_VERIFIED",
                    "customFields": [],
                    "mobileNo": {
                        "code": "+91",
                        "number": 987654321
                    },
                    "inGameId": "0987654321234567890",
                    "inGameName": "Ingamename",
                    "inDiscordId": "Praj261#6136",
                    "tournamentId": null,
                    "teamId": "62e135bedee2e3001371f54d",
                    "role": "substitutes",
                    "id": "61bad6907daaa50014f113fd"
                },
                {
                    "_id": "6316eb201f0b7e0013f7442c",
                    "email": "aakash6196@gmail.com",
                    "isDeleted": false,
                    "profilePicture": {
                        "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/5c199d00-3a5f-11ed-ac66-91d4298a9f48",
                        "name": "file",
                        "size": 80772,
                        "type": "image/jpeg"
                    },
                    "name": "sssa",
                    "createdAt": "2022-09-06T06:39:28.745Z",
                    "updatedAt": "2022-09-06T06:39:28.745Z",
                    "__v": 0,
                    "countryCode": {
                        "name": "India",
                        "code": "+91",
                        "abbreviation": "IN",
                        "number": "8521479630"
                    },
                    "discordProfile": {
                        "id": "899983946463449109",
                        "discordUsername": "aakash-7700#7006",
                        "name": "aakash-7700",
                        "verified": true,
                        "email": "aakash.sonawane@virtoustack.com",
                        "profilePicture": "018a7e587cc61fba849acaf1e4306502"
                    },
                    "kycVerified": false,
                    "verificationStatus": "VERIFIED",
                    "den": "631f11336f4d2a3580771e17",
                    "block": false,
                    "ban": true,
                    "customFields": [],
                    "mobileNo": {
                        "code": "+91",
                        "number": 234567890
                    },
                    "inGameId": "345678",
                    "inGameName": "sssa",
                    "inDiscordId": "aakash-7700#7006",
                    "tournamentId": null,
                    "teamId": "62e135bedee2e3001371f54d",
                    "role": "substitutes",
                    "id": "61a4b94ccbec4b0012cb40e4"
                }
            ]
        },
        "isDeleted": false,
        "teamCreatedBy": "61a4bf76cbec4b0012cb4336",
        "createdAt": "2022-07-27T12:55:26.605Z",
        "updatedAt": "2022-10-04T10:27:22.998Z",
        "__v": 0,
        "ban": false,
        "block": false,
        "clanDetails": {
            "kycVerified": false,
            "aka": "false",
            "isVerified": false,
            "den": null,
            "_id": "62e11d8cdee2e3001371c095",
            "name": "Shinobi Clan",
            "logo": {
                "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/c379ebc0-0d9c-11ed-8be9-277906d0995e",
                "name": "file",
                "size": 1231917,
                "type": "image/png"
            },
            "coverImage": {
                "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/dd67f3b0-0d9c-11ed-8be9-277906d0995e",
                "name": "file",
                "size": 178192,
                "type": "image/jpeg"
            },
            "isActive": true,
            "bio": "A ninja or shinobi was a covert agent or mercenary in feudal Japan. The functions of a ninja included reconnaissance, espionage, infiltration, deception, ambush, bodyguarding and their fighting skills in martial arts, including ninjutsu.",
            "verificationStatus": "VERIFIED",
            "isDeleted": false,
            "members": {
                "owner": [
                    {
                        "_id": "61a4bf76cbec4b0012cb4336",
                        "email": "sharief.shaik@virtoustack.com",
                        "username": "Nawaz",
                        "profilePicture": {
                            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/35696ec0-408a-11ed-bafc-47de7fa3dcee",
                            "name": "file",
                            "size": 11300,
                            "type": "image/jpeg"
                        },
                        "name": "Naruto"
                    },
                    {
                        "_id": "6281f1cef326df0011957999",
                        "email": "salman.shaikh@virtoustack.com",
                        "username": "salman.shaikh",
                        "profilePicture": {
                            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/83721020-3a3d-11ed-ac66-91d4298a9f48",
                            "name": "file",
                            "size": 73167,
                            "type": "image/jpeg"
                        },
                        "name": "salman.shaikh"
                    },
                    {
                        "_id": "61e7aef07ad4e30011267dab",
                        "username": "vinay.bojja",
                        "name": "Vinay Bojja",
                        "email": "vinay.bojja@virtoustack.com",
                        "profilePicture": {
                            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/1fe40040-0f36-11ed-946d-d7dba852c31f",
                            "name": "file",
                            "size": 57366,
                            "type": "image/png"
                        }
                    },
                    {
                        "_id": "61f52a2f7b529c00138d2e22",
                        "email": "manoj.zingare@virtoustack.com",
                        "username": "ManojZZ",
                        "profilePicture": {
                            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/024f77e0-f121-11ec-89f3-2d25dc7bc6f1",
                            "name": "file",
                            "size": 280777,
                            "type": "image/jpeg"
                        },
                        "name": "ManojZ"
                    }
                ],
                "creator": {
                    "_id": "61a4bf76cbec4b0012cb4336",
                    "email": "sharief.shaik@virtoustack.com",
                    "username": "Nawaz",
                    "profilePicture": {
                        "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/35696ec0-408a-11ed-bafc-47de7fa3dcee",
                        "name": "file",
                        "size": 11300,
                        "type": "image/jpeg"
                    },
                    "name": "Naruto"
                },
                "admin": [
                    {
                        "_id": "62d156ffea957100124b0b56",
                        "email": "chetna.kasturwar@virtoustack.com",
                        "username": "chetna.kasturwar",
                        "profilePicture": {
                            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/profile-letters/letter-c.png",
                            "name": "Default-Avatar",
                            "size": 300000,
                            "type": "img/jpg",
                            "createdAt": "2022-07-15T12:01:03.540Z"
                        },
                        "name": "chetna.kasturwar"
                    }
                ]
            },
            "clanVariant": 1,
            "createdAt": "2022-07-27T11:12:12.055Z",
            "updatedAt": "2022-10-15T12:21:17.601Z",
            "__v": 0
        },
        "gameDetails": {
            "regions": [
                "North America",
                "South America",
                "Europe",
                "KRJP",
                "Asia"
            ],
            "platforms": [
                "MOBILE"
            ],
            "participantsAllowedPerTeam": [
                2,
                4
            ],
            "isGameEnabled": true,
            "isBettingEnabled": true,
            "isEntryFeeEnabled": true,
            "isPrizePoolEnabled": true,
            "pointMatrix": [
                10,
                12,
                10,
                8,
                6,
                4,
                2,
                1,
                1,
                1
            ],
            "_id": "624fb8520318477ac01de652",
            "name": "PUBG Mobile",
            "bio": "PlayerUnknown's Battlegrounds Mobile",
            "maxPlayers": 100,
            "isDeleted": false,
            "createdAt": "2021-12-18T06:00:04.405Z",
            "updatedAt": "2022-09-22T09:34:55.567Z",
            "_v": 0,
            "logo": {
                "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/c4668c50-08d3-11ed-83c6-81926d37ca11",
                "name": "file",
                "size": 392971,
                "type": "image/png"
            },
            "pointsPerKill": 1,
            "__v": 27,
            "participantType": "BOTH",
            "maxNoOfTeams": 25,
            "maxPlayersPerTeam": 4,
            "bettingStatementId": [],
            "pointSystem": {
                "1": 10,
                "2": 12,
                "3": 10,
                "4": 8,
                "5": 6,
                "6": 4,
                "7": 2,
                "8": 1,
                "9": 1,
                "10": 1,
                "11": 1,
                "12": 1,
                "13": 1,
                "14": 1,
                "15": 1,
                "16": 1
            },
            "activeBetLogo": {
                "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/855ccb30-f778-11ec-8c7a-059e09126fc2",
                "name": "file",
                "size": 7999,
                "type": "image/png"
            },
            "disabledBetLogo": {
                "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/7c370700-f778-11ec-8c7a-059e09126fc2",
                "name": "file",
                "size": 3476,
                "type": "image/png"
            },
            "isActive": true
        }
    },
    {
        "_id": "62e3c30ef736b7001258fdc9",
        "clanId": "62e11d8cdee2e3001371c095",
        "gameId": "6195eb4946f53e5ad0eb1055",
        "name": "CS:GO 3",
        "isClanLogo": false,
        "logo": {
            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/c379ebc0-0d9c-11ed-8be9-277906d0995e",
            "name": "file",
            "size": 1231917,
            "type": "image/png"
        },
        "members": {
            "players": [
                {
                    "_id": "62e3c328f736b7001258fe02",
                    "email": "sharief.shaik@virtoustack.com",
                    "isDeleted": false,
                    "profilePicture": {
                        "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/35696ec0-408a-11ed-bafc-47de7fa3dcee",
                        "name": "file",
                        "size": 11300,
                        "type": "image/jpeg"
                    },
                    "name": "Nawaz",
                    "createdAt": "2022-07-29T11:23:20.267Z",
                    "updatedAt": "2022-07-29T11:23:20.267Z",
                    "__v": 0,
                    "countryCode": {
                        "name": "India",
                        "code": "+91",
                        "abbreviation": "IN",
                        "number": "0987654321"
                    },
                    "discordProfile": {
                        "id": "747408958616240130",
                        "discordUsername": "Nawaz#7947",
                        "name": "Nawaz",
                        "verified": true,
                        "email": "shariefnawaz1995@gmail.com",
                        "profilePicture": null
                    },
                    "kycVerified": false,
                    "den": "6322f4747c0961695c5cd480",
                    "isVerified": "NOT_VERIFIED",
                    "verificationStatus": "VERIFIED",
                    "block": false,
                    "customFields": [],
                    "mobileNo": {
                        "code": "+91",
                        "number": 7985641230
                    },
                    "inGameId": "79866454657822222",
                    "inGameName": "Nawazzz",
                    "inDiscordId": "Nawaz#7947",
                    "tournamentId": null,
                    "teamId": "62e3c30ef736b7001258fdc9",
                    "role": "players",
                    "id": "61a4bf76cbec4b0012cb4336"
                },
                {
                    "_id": "62e3c35ef736b7001258fef9",
                    "countryCode": {
                        "name": "India",
                        "code": "+91",
                        "abbreviation": "IN",
                        "number": 12121212121
                    },
                    "email": "salman.shaikh@virtoustack.com",
                    "isDeleted": false,
                    "profilePicture": {
                        "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/83721020-3a3d-11ed-ac66-91d4298a9f48",
                        "name": "file",
                        "size": 73167,
                        "type": "image/jpeg"
                    },
                    "name": "salman",
                    "createdAt": "2022-07-29T11:24:14.664Z",
                    "updatedAt": "2022-07-29T11:24:14.664Z",
                    "__v": 0,
                    "discordProfile": {
                        "id": "610856223700549643",
                        "discordUsername": "Salman3xs#6165",
                        "name": "Salman3xs",
                        "verified": true,
                        "email": "salman3xs@gmail.com",
                        "profilePicture": "eff217b7a2cbd6a590f26526a0575ab5"
                    },
                    "kycVerified": false,
                    "den": null,
                    "isVerified": "NOT_VERIFIED",
                    "verificationStatus": "NOT_VERIFIED",
                    "customFields": [],
                    "mobileNo": {
                        "code": "+91",
                        "number": 1234567890
                    },
                    "inGameId": "12122112212112211",
                    "inGameName": "salan",
                    "inDiscordId": "Salman3xs#6165",
                    "tournamentId": null,
                    "teamId": "62e3c30ef736b7001258fdc9",
                    "role": "players",
                    "id": "6281f1cef326df0011957999"
                },
                {
                    "_id": "62e3c45df736b70012591d21",
                    "email": "aakash6196@gmail.com",
                    "isDeleted": false,
                    "profilePicture": {
                        "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/5c199d00-3a5f-11ed-ac66-91d4298a9f48",
                        "name": "file",
                        "size": 80772,
                        "type": "image/jpeg"
                    },
                    "name": "Ninja",
                    "createdAt": "2022-07-29T11:28:29.876Z",
                    "updatedAt": "2022-07-29T11:28:29.876Z",
                    "__v": 0,
                    "countryCode": {
                        "name": "India",
                        "code": "+91",
                        "abbreviation": "IN",
                        "number": "8521479630"
                    },
                    "discordProfile": {
                        "id": "899983946463449109",
                        "discordUsername": "aakash-7700#7006",
                        "name": "aakash-7700",
                        "verified": true,
                        "email": "aakash.sonawane@virtoustack.com",
                        "profilePicture": "018a7e587cc61fba849acaf1e4306502"
                    },
                    "kycVerified": false,
                    "verificationStatus": "VERIFIED",
                    "den": "631f11336f4d2a3580771e17",
                    "block": false,
                    "ban": true,
                    "customFields": [],
                    "mobileNo": {
                        "code": "+91",
                        "number": 8521479630
                    },
                    "inGameId": "12345678901234567",
                    "inGameName": "Ninja",
                    "inDiscordId": "aakash-7700#7006",
                    "tournamentId": null,
                    "teamId": "62e3c30ef736b7001258fdc9",
                    "role": "players",
                    "id": "61a4b94ccbec4b0012cb40e4"
                }
            ],
            "captain": {
                "_id": "62e3c328f736b7001258fe02",
                "email": "sharief.shaik@virtoustack.com",
                "isDeleted": false,
                "profilePicture": {
                    "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/35696ec0-408a-11ed-bafc-47de7fa3dcee",
                    "name": "file",
                    "size": 11300,
                    "type": "image/jpeg"
                },
                "name": "Nawaz",
                "createdAt": "2022-07-29T11:23:20.267Z",
                "updatedAt": "2022-07-29T11:23:20.267Z",
                "__v": 0,
                "countryCode": {
                    "name": "India",
                    "code": "+91",
                    "abbreviation": "IN",
                    "number": "0987654321"
                },
                "discordProfile": {
                    "id": "747408958616240130",
                    "discordUsername": "Nawaz#7947",
                    "name": "Nawaz",
                    "verified": true,
                    "email": "shariefnawaz1995@gmail.com",
                    "profilePicture": null
                },
                "kycVerified": false,
                "den": "6322f4747c0961695c5cd480",
                "isVerified": "NOT_VERIFIED",
                "verificationStatus": "VERIFIED",
                "block": false,
                "customFields": [],
                "mobileNo": {
                    "code": "+91",
                    "number": 7985641230
                },
                "inGameId": "79866454657822222",
                "inGameName": "Nawazzz",
                "inDiscordId": "Nawaz#7947",
                "tournamentId": null,
                "teamId": "62e3c30ef736b7001258fdc9",
                "role": "players",
                "id": "61a4bf76cbec4b0012cb4336"
            },
            "substitutes": []
        },
        "isDeleted": false,
        "teamCreatedBy": "61a4bf76cbec4b0012cb4336",
        "createdAt": "2022-07-29T11:22:54.274Z",
        "updatedAt": "2022-08-22T11:06:48.329Z",
        "__v": 0,
        "ban": false,
        "block": false,
        "clanDetails": {
            "kycVerified": false,
            "aka": "false",
            "isVerified": false,
            "den": null,
            "_id": "62e11d8cdee2e3001371c095",
            "name": "Shinobi Clan",
            "logo": {
                "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/c379ebc0-0d9c-11ed-8be9-277906d0995e",
                "name": "file",
                "size": 1231917,
                "type": "image/png"
            },
            "coverImage": {
                "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/dd67f3b0-0d9c-11ed-8be9-277906d0995e",
                "name": "file",
                "size": 178192,
                "type": "image/jpeg"
            },
            "isActive": true,
            "bio": "A ninja or shinobi was a covert agent or mercenary in feudal Japan. The functions of a ninja included reconnaissance, espionage, infiltration, deception, ambush, bodyguarding and their fighting skills in martial arts, including ninjutsu.",
            "verificationStatus": "VERIFIED",
            "isDeleted": false,
            "members": {
                "owner": [
                    {
                        "_id": "61a4bf76cbec4b0012cb4336",
                        "email": "sharief.shaik@virtoustack.com",
                        "username": "Nawaz",
                        "profilePicture": {
                            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/35696ec0-408a-11ed-bafc-47de7fa3dcee",
                            "name": "file",
                            "size": 11300,
                            "type": "image/jpeg"
                        },
                        "name": "Naruto"
                    },
                    {
                        "_id": "6281f1cef326df0011957999",
                        "email": "salman.shaikh@virtoustack.com",
                        "username": "salman.shaikh",
                        "profilePicture": {
                            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/83721020-3a3d-11ed-ac66-91d4298a9f48",
                            "name": "file",
                            "size": 73167,
                            "type": "image/jpeg"
                        },
                        "name": "salman.shaikh"
                    },
                    {
                        "_id": "61e7aef07ad4e30011267dab",
                        "username": "vinay.bojja",
                        "name": "Vinay Bojja",
                        "email": "vinay.bojja@virtoustack.com",
                        "profilePicture": {
                            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/1fe40040-0f36-11ed-946d-d7dba852c31f",
                            "name": "file",
                            "size": 57366,
                            "type": "image/png"
                        }
                    },
                    {
                        "_id": "61f52a2f7b529c00138d2e22",
                        "email": "manoj.zingare@virtoustack.com",
                        "username": "ManojZZ",
                        "profilePicture": {
                            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/024f77e0-f121-11ec-89f3-2d25dc7bc6f1",
                            "name": "file",
                            "size": 280777,
                            "type": "image/jpeg"
                        },
                        "name": "ManojZ"
                    }
                ],
                "creator": {
                    "_id": "61a4bf76cbec4b0012cb4336",
                    "email": "sharief.shaik@virtoustack.com",
                    "username": "Nawaz",
                    "profilePicture": {
                        "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/35696ec0-408a-11ed-bafc-47de7fa3dcee",
                        "name": "file",
                        "size": 11300,
                        "type": "image/jpeg"
                    },
                    "name": "Naruto"
                },
                "admin": [
                    {
                        "_id": "62d156ffea957100124b0b56",
                        "email": "chetna.kasturwar@virtoustack.com",
                        "username": "chetna.kasturwar",
                        "profilePicture": {
                            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/profile-letters/letter-c.png",
                            "name": "Default-Avatar",
                            "size": 300000,
                            "type": "img/jpg",
                            "createdAt": "2022-07-15T12:01:03.540Z"
                        },
                        "name": "chetna.kasturwar"
                    }
                ]
            },
            "clanVariant": 1,
            "createdAt": "2022-07-27T11:12:12.055Z",
            "updatedAt": "2022-10-15T12:21:17.601Z",
            "__v": 0
        },
        "gameDetails": {
            "regions": [
                "Asia",
                "CIS",
                "Europe",
                "North America",
                "Oceania",
                "South America"
            ],
            "platforms": [
                "PC",
                "XBOX",
                "PLAYSTATION"
            ],
            "participantsAllowedPerTeam": [
                3,
                5
            ],
            "isGameEnabled": true,
            "isBettingEnabled": true,
            "isEntryFeeEnabled": true,
            "isPrizePoolEnabled": true,
            "pointMatrix": [
                null,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                0,
                10
            ],
            "_id": "6195eb4946f53e5ad0eb1055",
            "name": "CS:GO",
            "logo": {
                "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/2a535fb0-37ec-11ed-bb59-413626071d5a",
                "name": "file",
                "size": 400428,
                "type": "image/png"
            },
            "bio": "Counter-Strike: Global Offensive ",
            "maxPlayers": 10,
            "isDeleted": false,
            "createdAt": "2021-11-18T05:57:29.046Z",
            "updatedAt": "2022-09-22T12:34:06.661Z",
            "__v": 29,
            "pointsPerKill": 10,
            "participantType": "BOTH",
            "maxNoOfTeams": 2,
            "maxPlayersPerTeam": 5,
            "activeBetLogo": {
                "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/b9fd4a70-08b9-11ed-83c6-81926d37ca11",
                "name": "file",
                "size": 8600,
                "type": "image/png"
            },
            "disabledBetLogo": {
                "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/aaf0c070-08b9-11ed-83c6-81926d37ca11",
                "name": "file",
                "size": 3791,
                "type": "image/png"
            },
            "pointSystem": {
                "1": null,
                "2": 0,
                "3": 0,
                "4": 0,
                "5": 0,
                "6": 0,
                "7": 0,
                "8": 0,
                "9": 0,
                "10": 0,
                "11": 0,
                "12": 0,
                "13": 0,
                "14": 0,
                "15": 0,
                "16": 0
            },
            "isActive": true
        }
    },
    {
        "_id": "62ee4709cc208e0011772a25",
        "clanId": "62e11d8cdee2e3001371c095",
        "gameId": "61bd778c3f6e246d3b366970",
        "name": "PUBG 2",
        "isClanLogo": true,
        "logo": {
            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/c379ebc0-0d9c-11ed-8be9-277906d0995e",
            "name": "file",
            "size": 1231917,
            "type": "image/png"
        },
        "members": {
            "players": [
                {
                    "_id": "62ee4721cc208e0011772a40",
                    "email": "sharief.shaik@virtoustack.com",
                    "isDeleted": false,
                    "profilePicture": {
                        "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/35696ec0-408a-11ed-bafc-47de7fa3dcee",
                        "name": "file",
                        "size": 11300,
                        "type": "image/jpeg"
                    },
                    "name": "Nawaz",
                    "createdAt": "2022-08-06T10:49:05.442Z",
                    "updatedAt": "2022-08-06T10:49:05.442Z",
                    "__v": 0,
                    "countryCode": {
                        "name": "India",
                        "code": "+91",
                        "abbreviation": "IN",
                        "number": "0987654321"
                    },
                    "discordProfile": {
                        "id": "747408958616240130",
                        "discordUsername": "Nawaz#7947",
                        "name": "Nawaz",
                        "verified": true,
                        "email": "shariefnawaz1995@gmail.com",
                        "profilePicture": null
                    },
                    "kycVerified": false,
                    "den": "6322f4747c0961695c5cd480",
                    "isVerified": "NOT_VERIFIED",
                    "verificationStatus": "VERIFIED",
                    "block": false,
                    "customFields": [],
                    "mobileNo": {
                        "code": "+91",
                        "number": 7986453210
                    },
                    "inGameId": "79865421323164587",
                    "inGameName": "nawazzz",
                    "inDiscordId": "Nawaz#7947",
                    "tournamentId": null,
                    "teamId": "62ee4709cc208e0011772a25",
                    "role": "players",
                    "id": "61a4bf76cbec4b0012cb4336"
                },
                {
                    "_id": "62ee4769cc208e0011772b44",
                    "email": "manoj.zingare@virtoustack.com",
                    "isDeleted": false,
                    "profilePicture": {
                        "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/024f77e0-f121-11ec-89f3-2d25dc7bc6f1",
                        "name": "file",
                        "size": 280777,
                        "type": "image/jpeg"
                    },
                    "name": "ManojZ",
                    "createdAt": "2022-08-06T10:50:17.542Z",
                    "updatedAt": "2022-08-06T10:50:17.542Z",
                    "__v": 0,
                    "discordProfile": {
                        "id": "951357205616152609",
                        "discordUsername": "manojzingare#7200",
                        "name": "manojzingare",
                        "verified": true,
                        "email": "manoj.zingare@virtoustack.com",
                        "profilePicture": null
                    },
                    "countryCode": {
                        "name": "India",
                        "code": "+91",
                        "abbreviation": "IN",
                        "number": 8765678765
                    },
                    "kycVerified": false,
                    "den": "633fd95b6a9f9900112bdd97",
                    "isVerified": "false",
                    "verificationStatus": "NOT_VERIFIED",
                    "block": false,
                    "customFields": [],
                    "mobileNo": {
                        "code": "+91",
                        "number": 9876543456
                    },
                    "inGameId": "87654323454567345",
                    "inGameName": "Manojz",
                    "inDiscordId": "manojzingare#7200",
                    "tournamentId": null,
                    "teamId": "62ee4709cc208e0011772a25",
                    "role": "players",
                    "id": "61f52a2f7b529c00138d2e22"
                }
            ],
            "captain": {
                "_id": "62ee4721cc208e0011772a40",
                "email": "sharief.shaik@virtoustack.com",
                "isDeleted": false,
                "profilePicture": {
                    "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/35696ec0-408a-11ed-bafc-47de7fa3dcee",
                    "name": "file",
                    "size": 11300,
                    "type": "image/jpeg"
                },
                "name": "Nawaz",
                "createdAt": "2022-08-06T10:49:05.442Z",
                "updatedAt": "2022-08-06T10:49:05.442Z",
                "__v": 0,
                "countryCode": {
                    "name": "India",
                    "code": "+91",
                    "abbreviation": "IN",
                    "number": "0987654321"
                },
                "discordProfile": {
                    "id": "747408958616240130",
                    "discordUsername": "Nawaz#7947",
                    "name": "Nawaz",
                    "verified": true,
                    "email": "shariefnawaz1995@gmail.com",
                    "profilePicture": null
                },
                "kycVerified": false,
                "den": "6322f4747c0961695c5cd480",
                "isVerified": "NOT_VERIFIED",
                "verificationStatus": "VERIFIED",
                "block": false,
                "customFields": [],
                "mobileNo": {
                    "code": "+91",
                    "number": 7986453210
                },
                "inGameId": "79865421323164587",
                "inGameName": "nawazzz",
                "inDiscordId": "Nawaz#7947",
                "tournamentId": null,
                "teamId": "62ee4709cc208e0011772a25",
                "role": "players",
                "id": "61a4bf76cbec4b0012cb4336"
            }
        },
        "isDeleted": false,
        "teamCreatedBy": "61a4bf76cbec4b0012cb4336",
        "createdAt": "2022-08-06T10:48:41.318Z",
        "updatedAt": "2022-10-13T05:09:07.197Z",
        "__v": 0,
        "ban": false,
        "block": false,
        "clanDetails": {
            "kycVerified": false,
            "aka": "false",
            "isVerified": false,
            "den": null,
            "_id": "62e11d8cdee2e3001371c095",
            "name": "Shinobi Clan",
            "logo": {
                "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/c379ebc0-0d9c-11ed-8be9-277906d0995e",
                "name": "file",
                "size": 1231917,
                "type": "image/png"
            },
            "coverImage": {
                "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/dd67f3b0-0d9c-11ed-8be9-277906d0995e",
                "name": "file",
                "size": 178192,
                "type": "image/jpeg"
            },
            "isActive": true,
            "bio": "A ninja or shinobi was a covert agent or mercenary in feudal Japan. The functions of a ninja included reconnaissance, espionage, infiltration, deception, ambush, bodyguarding and their fighting skills in martial arts, including ninjutsu.",
            "verificationStatus": "VERIFIED",
            "isDeleted": false,
            "members": {
                "owner": [
                    {
                        "_id": "61a4bf76cbec4b0012cb4336",
                        "email": "sharief.shaik@virtoustack.com",
                        "username": "Nawaz",
                        "profilePicture": {
                            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/35696ec0-408a-11ed-bafc-47de7fa3dcee",
                            "name": "file",
                            "size": 11300,
                            "type": "image/jpeg"
                        },
                        "name": "Naruto"
                    },
                    {
                        "_id": "6281f1cef326df0011957999",
                        "email": "salman.shaikh@virtoustack.com",
                        "username": "salman.shaikh",
                        "profilePicture": {
                            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/83721020-3a3d-11ed-ac66-91d4298a9f48",
                            "name": "file",
                            "size": 73167,
                            "type": "image/jpeg"
                        },
                        "name": "salman.shaikh"
                    },
                    {
                        "_id": "61e7aef07ad4e30011267dab",
                        "username": "vinay.bojja",
                        "name": "Vinay Bojja",
                        "email": "vinay.bojja@virtoustack.com",
                        "profilePicture": {
                            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/1fe40040-0f36-11ed-946d-d7dba852c31f",
                            "name": "file",
                            "size": 57366,
                            "type": "image/png"
                        }
                    },
                    {
                        "_id": "61f52a2f7b529c00138d2e22",
                        "email": "manoj.zingare@virtoustack.com",
                        "username": "ManojZZ",
                        "profilePicture": {
                            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/024f77e0-f121-11ec-89f3-2d25dc7bc6f1",
                            "name": "file",
                            "size": 280777,
                            "type": "image/jpeg"
                        },
                        "name": "ManojZ"
                    }
                ],
                "creator": {
                    "_id": "61a4bf76cbec4b0012cb4336",
                    "email": "sharief.shaik@virtoustack.com",
                    "username": "Nawaz",
                    "profilePicture": {
                        "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/35696ec0-408a-11ed-bafc-47de7fa3dcee",
                        "name": "file",
                        "size": 11300,
                        "type": "image/jpeg"
                    },
                    "name": "Naruto"
                },
                "admin": [
                    {
                        "_id": "62d156ffea957100124b0b56",
                        "email": "chetna.kasturwar@virtoustack.com",
                        "username": "chetna.kasturwar",
                        "profilePicture": {
                            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/profile-letters/letter-c.png",
                            "name": "Default-Avatar",
                            "size": 300000,
                            "type": "img/jpg",
                            "createdAt": "2022-07-15T12:01:03.540Z"
                        },
                        "name": "chetna.kasturwar"
                    }
                ]
            },
            "clanVariant": 1,
            "createdAt": "2022-07-27T11:12:12.055Z",
            "updatedAt": "2022-10-15T12:21:17.601Z",
            "__v": 0
        },
        "gameDetails": {
            "regions": [
                "North America",
                "South America",
                "Europe",
                "KRJP",
                "Asia"
            ],
            "platforms": [
                "PC"
            ],
            "participantsAllowedPerTeam": [
                2,
                4
            ],
            "isGameEnabled": true,
            "isBettingEnabled": true,
            "isEntryFeeEnabled": false,
            "isPrizePoolEnabled": true,
            "pointMatrix": [
                null,
                12,
                10,
                8,
                7,
                6,
                5,
                4,
                4,
                1
            ],
            "_id": "61bd778c3f6e246d3b366970",
            "name": "PUBG",
            "bio": "PlayerUnknown's Battlegrounds",
            "maxPlayers": 100,
            "isDeleted": false,
            "createdAt": "2021-12-18T06:00:04.405Z",
            "updatedAt": "2022-10-06T13:34:03.738Z",
            "_v": "0",
            "logo": {
                "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/e3498000-08d3-11ed-83c6-81926d37ca11",
                "name": "file",
                "size": 433130,
                "type": "image/png"
            },
            "pointsPerKill": 1,
            "__v": 21,
            "participantType": "BOTH",
            "maxNoOfTeams": 25,
            "maxPlayersPerTeam": 4,
            "pointSystem": {
                "1": null,
                "2": 12,
                "3": 10,
                "4": 8,
                "5": 7,
                "6": 6,
                "7": 5,
                "8": 4,
                "9": 4,
                "10": 4,
                "11": 4,
                "12": 4,
                "13": 4,
                "14": 4,
                "15": 4,
                "16": 4
            },
            "activeBetLogo": {
                "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/e41bc2f0-08b9-11ed-83c6-81926d37ca11",
                "name": "file",
                "size": 15353,
                "type": "image/png"
            },
            "disabledBetLogo": {
                "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/cedfd160-08b9-11ed-83c6-81926d37ca11",
                "name": "file",
                "size": 5428,
                "type": "image/png"
            },
            "isActive": true
        }
    },
    {
        "_id": "6345600f562b1e001374dc72",
        "clanId": "62e11d8cdee2e3001371c095",
        "gameId": "624fb8520318477ac01de652",
        "name": "Team BGMI",
        "isClanLogo": false,
        "logo": {
            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/c379ebc0-0d9c-11ed-8be9-277906d0995e",
            "name": "file",
            "size": 1231917,
            "type": "image/png"
        },
        "members": {
            "players": []
        },
        "isDeleted": false,
        "teamCreatedBy": "61a4bf76cbec4b0012cb4336",
        "createdAt": "2022-10-11T12:22:39.638Z",
        "updatedAt": "2022-10-11T12:22:39.638Z",
        "__v": 0,
        "clanDetails": {
            "kycVerified": false,
            "aka": "false",
            "isVerified": false,
            "den": null,
            "_id": "62e11d8cdee2e3001371c095",
            "name": "Shinobi Clan",
            "logo": {
                "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/c379ebc0-0d9c-11ed-8be9-277906d0995e",
                "name": "file",
                "size": 1231917,
                "type": "image/png"
            },
            "coverImage": {
                "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/dd67f3b0-0d9c-11ed-8be9-277906d0995e",
                "name": "file",
                "size": 178192,
                "type": "image/jpeg"
            },
            "isActive": true,
            "bio": "A ninja or shinobi was a covert agent or mercenary in feudal Japan. The functions of a ninja included reconnaissance, espionage, infiltration, deception, ambush, bodyguarding and their fighting skills in martial arts, including ninjutsu.",
            "verificationStatus": "VERIFIED",
            "isDeleted": false,
            "members": {
                "owner": [
                    {
                        "_id": "61a4bf76cbec4b0012cb4336",
                        "email": "sharief.shaik@virtoustack.com",
                        "username": "Nawaz",
                        "profilePicture": {
                            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/35696ec0-408a-11ed-bafc-47de7fa3dcee",
                            "name": "file",
                            "size": 11300,
                            "type": "image/jpeg"
                        },
                        "name": "Naruto"
                    },
                    {
                        "_id": "6281f1cef326df0011957999",
                        "email": "salman.shaikh@virtoustack.com",
                        "username": "salman.shaikh",
                        "profilePicture": {
                            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/83721020-3a3d-11ed-ac66-91d4298a9f48",
                            "name": "file",
                            "size": 73167,
                            "type": "image/jpeg"
                        },
                        "name": "salman.shaikh"
                    },
                    {
                        "_id": "61e7aef07ad4e30011267dab",
                        "username": "vinay.bojja",
                        "name": "Vinay Bojja",
                        "email": "vinay.bojja@virtoustack.com",
                        "profilePicture": {
                            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/1fe40040-0f36-11ed-946d-d7dba852c31f",
                            "name": "file",
                            "size": 57366,
                            "type": "image/png"
                        }
                    },
                    {
                        "_id": "61f52a2f7b529c00138d2e22",
                        "email": "manoj.zingare@virtoustack.com",
                        "username": "ManojZZ",
                        "profilePicture": {
                            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/024f77e0-f121-11ec-89f3-2d25dc7bc6f1",
                            "name": "file",
                            "size": 280777,
                            "type": "image/jpeg"
                        },
                        "name": "ManojZ"
                    }
                ],
                "creator": {
                    "_id": "61a4bf76cbec4b0012cb4336",
                    "email": "sharief.shaik@virtoustack.com",
                    "username": "Nawaz",
                    "profilePicture": {
                        "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/35696ec0-408a-11ed-bafc-47de7fa3dcee",
                        "name": "file",
                        "size": 11300,
                        "type": "image/jpeg"
                    },
                    "name": "Naruto"
                },
                "admin": [
                    {
                        "_id": "62d156ffea957100124b0b56",
                        "email": "chetna.kasturwar@virtoustack.com",
                        "username": "chetna.kasturwar",
                        "profilePicture": {
                            "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/profile-letters/letter-c.png",
                            "name": "Default-Avatar",
                            "size": 300000,
                            "type": "img/jpg",
                            "createdAt": "2022-07-15T12:01:03.540Z"
                        },
                        "name": "chetna.kasturwar"
                    }
                ]
            },
            "clanVariant": 1,
            "createdAt": "2022-07-27T11:12:12.055Z",
            "updatedAt": "2022-10-15T12:21:17.601Z",
            "__v": 0
        },
        "gameDetails": {
            "regions": [
                "North America",
                "South America",
                "Europe",
                "KRJP",
                "Asia"
            ],
            "platforms": [
                "MOBILE"
            ],
            "participantsAllowedPerTeam": [
                2,
                4
            ],
            "isGameEnabled": true,
            "isBettingEnabled": true,
            "isEntryFeeEnabled": true,
            "isPrizePoolEnabled": true,
            "pointMatrix": [
                10,
                12,
                10,
                8,
                6,
                4,
                2,
                1,
                1,
                1
            ],
            "_id": "624fb8520318477ac01de652",
            "name": "PUBG Mobile",
            "bio": "PlayerUnknown's Battlegrounds Mobile",
            "maxPlayers": 100,
            "isDeleted": false,
            "createdAt": "2021-12-18T06:00:04.405Z",
            "updatedAt": "2022-09-22T09:34:55.567Z",
            "_v": 0,
            "logo": {
                "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/c4668c50-08d3-11ed-83c6-81926d37ca11",
                "name": "file",
                "size": 392971,
                "type": "image/png"
            },
            "pointsPerKill": 1,
            "__v": 27,
            "participantType": "BOTH",
            "maxNoOfTeams": 25,
            "maxPlayersPerTeam": 4,
            "bettingStatementId": [],
            "pointSystem": {
                "1": 10,
                "2": 12,
                "3": 10,
                "4": 8,
                "5": 6,
                "6": 4,
                "7": 2,
                "8": 1,
                "9": 1,
                "10": 1,
                "11": 1,
                "12": 1,
                "13": 1,
                "14": 1,
                "15": 1,
                "16": 1
            },
            "activeBetLogo": {
                "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/855ccb30-f778-11ec-8c7a-059e09126fc2",
                "name": "file",
                "size": 7999,
                "type": "image/png"
            },
            "disabledBetLogo": {
                "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/7c370700-f778-11ec-8c7a-059e09126fc2",
                "name": "file",
                "size": 3476,
                "type": "image/png"
            },
            "isActive": true
        }
    }
]

export const denFeed = [
    {
        "_id": "6347bd72ba0c920011ae2028",
        "assets": [
            {
                "url": "https://www.twitch.tv/dota2ti",
            }
        ],
        "hammer": [],
        "spotlight": [],
        "shares": [
            "6322f4747c0961695c5cd480"
        ],
        "isDeleted": false,
        "isHidden": false,
        "isDrafted": false,
        "feeling": null,
        "isPublic": true,
        "pinnedCommments": [],
        "disable": {
            "reaction": false,
            "comment": false,
            "share": false,
            "spotlight": false
        },
        "postAs": "USER",
        "type": "POST",
        "description": "Captain America: The First Avenger is a 2011 American superhero film based on the Marvel Comics character Captain America.",
        "likes": {
            "spark": [],
            "bomb": [
                "6322f4747c0961695c5cd480",
                "631f11336f4d2a3580771e17"
            ],
            "pan": [],
            "gun": [],
            "fire": []
        },
        "from": [
            {
                "_id": "631f11336f4d2a3580771e17",
                "countryCode": {
                    "name": "India",
                    "code": "+91",
                    "abbreviation": "IN"
                },
                "kycVerified": false,
                "gameOfInterest": [
                    "61bd76553f6e246d3b36696f"
                ],
                "myClan": [],
                "myOrganization": [],
                "isPublic": true,
                "isDeleted": true,
                "denPosts": 143,
                "savedPosts": [],
                "followers": [
                    "631f11336f4d2a3580771111",
                    "6322f4747c0961695c5cd480",
                    "6344f930562b1e0013743900",
                    "634d423edc6aa36ec7e169f5"
                ],
                "followings": [
                    "631f11336f4d2a3580771111",
                    "6322f4747c0961695c5cd480",
                    "634d423edc6aa36ec7e169f5",
                    "634a91419be80b0012be6f51"
                ],
                "requested": [],
                "requestedBy": [],
                "blocked": [
                    "6344f930562b1e0013743900"
                ],
                "blockedBy": [],
                "pinnedPosts": [],
                "topFansHammer": [],
                "topFansSpotlight": [],
                "achievementsHammer": [],
                "achievementsSpotlight": [],
                "innerCircles": [],
                "leaderBoard": [],
                "rank": "Newbie",
                "socialMedia": [],
                "Bio": "sdsfs",
                "miniDescription": "Test",
                "profileTag": "Shiva The Super Hero",
                "username": "aakash-007",
                "gender": "male",
                "foodPreference": "Non",
                "age": 24,
                "city": "false",
                "createdAt": "2022-09-12T11:00:03.370Z",
                "updatedAt": "2022-10-22T07:55:53.177Z",
                "__v": 548,
                "AKA": "aakash-007",
                "profileCover": {
                    "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/6a9ce710-4937-11ed-b799-7797054f82af",
                    "name": "file",
                    "size": 379672,
                    "type": "image/jpeg"
                },
                "privateStatus": {
                    "bettingStatistics": true,
                    "tournamentCount": true
                },
                "publicStatus": {
                    "matchPlayed": true,
                    "matchWin": true,
                    "spotlightCollection": true,
                    "mvpCount": true,
                    "gameStats": true
                },
                "isActive": true,
                "discordProfile": {
                    "id": "746969472723583016",
                    "discordUsername": "aakash-7700#7006",
                    "name": "aakash-7700",
                    "verified": true,
                    "email": "aakash.sonawane@virtoustack.com",
                    "profilePicture": "2329f8d018030061f6a5891b5dba22f9"
                },
                "email": "aakash6196@gmail.com",
                "name": "Shiva Kapoor",
                "phone": 8390514195,
                "profilePicture": {
                    "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/711ce910-4acb-11ed-bb77-e7d216c76e8e",
                    "name": "file",
                    "size": 75603,
                    "type": "image/jpeg"
                },
                "sharedPosts": [
                    "634516cba38c5835320df654",
                    "63454286b3e8b97396fd3b11",
                    "63464a50fd30482b2502bcd2",
                    "63464a60fd30482b2502bcff",
                    "63464add9df62d2f6b382e7b",
                    "63464b55609bf12fc9f085ab",
                    "6345364a562b1e001374a763",
                    "634a784ab8593720978d8fb1"
                ],
                "draftPosts": [
                    "634fe0d16cd8ac9fe4a6d76d"
                ],
                "isSparked": true,
                "sparks": {
                    "spark": 140,
                    "bomb": 108,
                    "pan": 49,
                    "gun": 50,
                    "fire": 50
                },
                "posts": [
                    ""
                ],
                "post": [
                    "634516cba38c5835320df654",
                    "634516cba38c5835320df654",
                    "63453b44ad4a465d73cbe4dd",
                    "63454286b3e8b97396fd3b11",
                    "63454286b3e8b97396fd3b11",
                    "63464a50fd30482b2502bcd2",
                    "63464a50fd30482b2502bcd2",
                    "63464a60fd30482b2502bcff",
                    "63464a60fd30482b2502bcff",
                    "63464add9df62d2f6b382e7b",
                    "63464add9df62d2f6b382e7b",
                    "63464b6f609bf12fc9f085b7",
                    "63464b55609bf12fc9f085ab",
                    "63465c99fb069c41974063b9",
                    "6345364a562b1e001374a763",
                    "634691aca06b7d737bb33a3f",
                    "634692daf5f81075aa34c83b",
                    "6347bc3fba0c920011ae1d1e",
                    "6347bc77ba0c920011ae1e0d",
                    "6347bd72ba0c920011ae2028",
                    "6347bdecba0c920011ae211a",
                    "6347be31ba0c920011ae2160",
                    "6347be90ba0c920011ae21df",
                    "6347beecba0c920011ae22e7",
                    "6347bf95ba0c920011ae24b6",
                    "6347c087ba0c920011ae261d",
                    "6347c112ba0c920011ae2760",
                    "6347c1b0ba0c920011ae27d5",
                    "634937bcba0c920011ae9368",
                    "63493ca86904ba17f390ec95",
                    "634a750ec80192183c972c1e",
                    "634cfe40a96d1e00127956e2",
                    "634a784ab8593720978d8fb1",
                    "634e70d7367c2174d38d0842",
                    "634f99a432ea3e49443f1fa9",
                    "634f9f0432ea3e49443f2131",
                    "6350da56378f933190c08ae2"
                ],
                "denStar": 163,
                "lastSpin": "2022-10-21T18:30:00.000Z",
                "hammer": 31,
                "type": "USER",
                "clanOrOrgId": "61a4b94ccbec4b0012cb40e4"
            }
        ],
        "createdAt": "2022-10-13T07:25:38.405Z",
        "updatedAt": "2022-10-21T09:43:08.771Z",
        "__v": 3,
        "rePostDetails": [],
        "rePostDetailsFrom": []
    },
    {
        "_id": "6347bdecba0c920011ae211a",
        "assets": [
            {
                "url": "https://www.twitch.tv/badgalshay"
            }
        ],
        "hammer": [],
        "spotlight": [],
        "shares": [],
        "isDeleted": false,
        "isHidden": false,
        "isDrafted": false,
        "feeling": null,
        "isPublic": true,
        "pinnedCommments": [],
        "disable": {
            "reaction": false,
            "comment": false,
            "share": false,
            "spotlight": false
        },
        "postAs": "USER",
        "type": "POST",
        "description": "While trying to take down Carlton, the CEO of Life Foundation, Eddie, a journalist, investigates experiments of human trials. Unwittingly, he gets merged with a symbiotic alien with lethal abilities.",
        "likes": {
            "spark": [
                "6322f4747c0961695c5cd480"
            ],
            "bomb": [],
            "pan": [],
            "gun": [],
            "fire": []
        },
        "from": [
            {
                "_id": "631f11336f4d2a3580771e17",
                "countryCode": {
                    "name": "India",
                    "code": "+91",
                    "abbreviation": "IN"
                },
                "kycVerified": false,
                "gameOfInterest": [
                    "61bd76553f6e246d3b36696f"
                ],
                "myClan": [],
                "myOrganization": [],
                "isPublic": true,
                "isDeleted": true,
                "denPosts": 143,
                "savedPosts": [],
                "followers": [
                    "631f11336f4d2a3580771111",
                    "6322f4747c0961695c5cd480",
                    "6344f930562b1e0013743900",
                    "634d423edc6aa36ec7e169f5"
                ],
                "followings": [
                    "631f11336f4d2a3580771111",
                    "6322f4747c0961695c5cd480",
                    "634d423edc6aa36ec7e169f5",
                    "634a91419be80b0012be6f51"
                ],
                "requested": [],
                "requestedBy": [],
                "blocked": [
                    "6344f930562b1e0013743900"
                ],
                "blockedBy": [],
                "pinnedPosts": [],
                "topFansHammer": [],
                "topFansSpotlight": [],
                "achievementsHammer": [],
                "achievementsSpotlight": [],
                "innerCircles": [],
                "leaderBoard": [],
                "rank": "Newbie",
                "socialMedia": [],
                "Bio": "sdsfs",
                "miniDescription": "Test",
                "profileTag": "Shiva The Super Hero",
                "username": "aakash-007",
                "gender": "male",
                "foodPreference": "Non",
                "age": 24,
                "city": "false",
                "createdAt": "2022-09-12T11:00:03.370Z",
                "updatedAt": "2022-10-22T07:55:53.177Z",
                "__v": 548,
                "AKA": "aakash-007",
                "profileCover": {
                    "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/6a9ce710-4937-11ed-b799-7797054f82af",
                    "name": "file",
                    "size": 379672,
                    "type": "image/jpeg"
                },
                "privateStatus": {
                    "bettingStatistics": true,
                    "tournamentCount": true
                },
                "publicStatus": {
                    "matchPlayed": true,
                    "matchWin": true,
                    "spotlightCollection": true,
                    "mvpCount": true,
                    "gameStats": true
                },
                "isActive": true,
                "discordProfile": {
                    "id": "746969472723583016",
                    "discordUsername": "aakash-7700#7006",
                    "name": "aakash-7700",
                    "verified": true,
                    "email": "aakash.sonawane@virtoustack.com",
                    "profilePicture": "2329f8d018030061f6a5891b5dba22f9"
                },
                "email": "aakash6196@gmail.com",
                "name": "Shiva Kapoor",
                "phone": 8390514195,
                "profilePicture": {
                    "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/711ce910-4acb-11ed-bb77-e7d216c76e8e",
                    "name": "file",
                    "size": 75603,
                    "type": "image/jpeg"
                },
                "sharedPosts": [
                    "634516cba38c5835320df654",
                    "63454286b3e8b97396fd3b11",
                    "63464a50fd30482b2502bcd2",
                    "63464a60fd30482b2502bcff",
                    "63464add9df62d2f6b382e7b",
                    "63464b55609bf12fc9f085ab",
                    "6345364a562b1e001374a763",
                    "634a784ab8593720978d8fb1"
                ],
                "draftPosts": [
                    "634fe0d16cd8ac9fe4a6d76d"
                ],
                "isSparked": true,
                "sparks": {
                    "spark": 140,
                    "bomb": 108,
                    "pan": 49,
                    "gun": 50,
                    "fire": 50
                },
                "posts": [
                    ""
                ],
                "post": [
                    "634516cba38c5835320df654",
                    "634516cba38c5835320df654",
                    "63453b44ad4a465d73cbe4dd",
                    "63454286b3e8b97396fd3b11",
                    "63454286b3e8b97396fd3b11",
                    "63464a50fd30482b2502bcd2",
                    "63464a50fd30482b2502bcd2",
                    "63464a60fd30482b2502bcff",
                    "63464a60fd30482b2502bcff",
                    "63464add9df62d2f6b382e7b",
                    "63464add9df62d2f6b382e7b",
                    "63464b6f609bf12fc9f085b7",
                    "63464b55609bf12fc9f085ab",
                    "63465c99fb069c41974063b9",
                    "6345364a562b1e001374a763",
                    "634691aca06b7d737bb33a3f",
                    "634692daf5f81075aa34c83b",
                    "6347bc3fba0c920011ae1d1e",
                    "6347bc77ba0c920011ae1e0d",
                    "6347bd72ba0c920011ae2028",
                    "6347bdecba0c920011ae211a",
                    "6347be31ba0c920011ae2160",
                    "6347be90ba0c920011ae21df",
                    "6347beecba0c920011ae22e7",
                    "6347bf95ba0c920011ae24b6",
                    "6347c087ba0c920011ae261d",
                    "6347c112ba0c920011ae2760",
                    "6347c1b0ba0c920011ae27d5",
                    "634937bcba0c920011ae9368",
                    "63493ca86904ba17f390ec95",
                    "634a750ec80192183c972c1e",
                    "634cfe40a96d1e00127956e2",
                    "634a784ab8593720978d8fb1",
                    "634e70d7367c2174d38d0842",
                    "634f99a432ea3e49443f1fa9",
                    "634f9f0432ea3e49443f2131",
                    "6350da56378f933190c08ae2"
                ],
                "denStar": 163,
                "lastSpin": "2022-10-21T18:30:00.000Z",
                "hammer": 31,
                "type": "USER",
                "clanOrOrgId": "61a4b94ccbec4b0012cb40e4"
            }
        ],
        "createdAt": "2022-10-13T07:27:40.710Z",
        "updatedAt": "2022-10-15T11:32:49.392Z",
        "__v": 1,
        "rePostDetails": [],
        "rePostDetailsFrom": []
    }
]

export const streamPost = [
    {
        "_id": "6347bd72ba0c920011ae2028",
        "assets": [
            {
                "url": "https://www.twitch.tv/dota2ti",
            }
        ],
        "hammer": [],
        "spotlight": [],
        "shares": [
            "6322f4747c0961695c5cd480"
        ],
        "isDeleted": false,
        "isHidden": false,
        "isDrafted": false,
        "feeling": null,
        "isPublic": true,
        "pinnedCommments": [],
        "disable": {
            "reaction": false,
            "comment": false,
            "share": false,
            "spotlight": false
        },
        "postAs": "USER",
        "type": "POST",
        "description": "Captain America: The First Avenger is a 2011 American superhero film based on the Marvel Comics character Captain America.",
        "likes": {
            "spark": [],
            "bomb": [
                "6322f4747c0961695c5cd480",
                "631f11336f4d2a3580771e17"
            ],
            "pan": [],
            "gun": [],
            "fire": []
        },
        "from": [
            {
                "_id": "631f11336f4d2a3580771e17",
                "countryCode": {
                    "name": "India",
                    "code": "+91",
                    "abbreviation": "IN"
                },
                "kycVerified": false,
                "gameOfInterest": [
                    "61bd76553f6e246d3b36696f"
                ],
                "myClan": [],
                "myOrganization": [],
                "isPublic": true,
                "isDeleted": true,
                "denPosts": 143,
                "savedPosts": [],
                "followers": [
                    "631f11336f4d2a3580771111",
                    "6322f4747c0961695c5cd480",
                    "6344f930562b1e0013743900",
                    "634d423edc6aa36ec7e169f5"
                ],
                "followings": [
                    "631f11336f4d2a3580771111",
                    "6322f4747c0961695c5cd480",
                    "634d423edc6aa36ec7e169f5",
                    "634a91419be80b0012be6f51"
                ],
                "requested": [],
                "requestedBy": [],
                "blocked": [
                    "6344f930562b1e0013743900"
                ],
                "blockedBy": [],
                "pinnedPosts": [],
                "topFansHammer": [],
                "topFansSpotlight": [],
                "achievementsHammer": [],
                "achievementsSpotlight": [],
                "innerCircles": [],
                "leaderBoard": [],
                "rank": "Newbie",
                "socialMedia": [],
                "Bio": "sdsfs",
                "miniDescription": "Test",
                "profileTag": "Shiva The Super Hero",
                "username": "aakash-007",
                "gender": "male",
                "foodPreference": "Non",
                "age": 24,
                "city": "false",
                "createdAt": "2022-09-12T11:00:03.370Z",
                "updatedAt": "2022-10-22T07:55:53.177Z",
                "__v": 548,
                "AKA": "aakash-007",
                "profileCover": {
                    "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/6a9ce710-4937-11ed-b799-7797054f82af",
                    "name": "file",
                    "size": 379672,
                    "type": "image/jpeg"
                },
                "privateStatus": {
                    "bettingStatistics": true,
                    "tournamentCount": true
                },
                "publicStatus": {
                    "matchPlayed": true,
                    "matchWin": true,
                    "spotlightCollection": true,
                    "mvpCount": true,
                    "gameStats": true
                },
                "isActive": true,
                "discordProfile": {
                    "id": "746969472723583016",
                    "discordUsername": "aakash-7700#7006",
                    "name": "aakash-7700",
                    "verified": true,
                    "email": "aakash.sonawane@virtoustack.com",
                    "profilePicture": "2329f8d018030061f6a5891b5dba22f9"
                },
                "email": "aakash6196@gmail.com",
                "name": "Shiva Kapoor",
                "phone": 8390514195,
                "profilePicture": {
                    "url": "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/711ce910-4acb-11ed-bb77-e7d216c76e8e",
                    "name": "file",
                    "size": 75603,
                    "type": "image/jpeg"
                },
                "sharedPosts": [
                    "634516cba38c5835320df654",
                    "63454286b3e8b97396fd3b11",
                    "63464a50fd30482b2502bcd2",
                    "63464a60fd30482b2502bcff",
                    "63464add9df62d2f6b382e7b",
                    "63464b55609bf12fc9f085ab",
                    "6345364a562b1e001374a763",
                    "634a784ab8593720978d8fb1"
                ],
                "draftPosts": [
                    "634fe0d16cd8ac9fe4a6d76d"
                ],
                "isSparked": true,
                "sparks": {
                    "spark": 140,
                    "bomb": 108,
                    "pan": 49,
                    "gun": 50,
                    "fire": 50
                },
                "posts": [
                    ""
                ],
                "post": [
                    "634516cba38c5835320df654",
                    "634516cba38c5835320df654",
                    "63453b44ad4a465d73cbe4dd",
                    "63454286b3e8b97396fd3b11",
                    "63454286b3e8b97396fd3b11",
                    "63464a50fd30482b2502bcd2",
                    "63464a50fd30482b2502bcd2",
                    "63464a60fd30482b2502bcff",
                    "63464a60fd30482b2502bcff",
                    "63464add9df62d2f6b382e7b",
                    "63464add9df62d2f6b382e7b",
                    "63464b6f609bf12fc9f085b7",
                    "63464b55609bf12fc9f085ab",
                    "63465c99fb069c41974063b9",
                    "6345364a562b1e001374a763",
                    "634691aca06b7d737bb33a3f",
                    "634692daf5f81075aa34c83b",
                    "6347bc3fba0c920011ae1d1e",
                    "6347bc77ba0c920011ae1e0d",
                    "6347bd72ba0c920011ae2028",
                    "6347bdecba0c920011ae211a",
                    "6347be31ba0c920011ae2160",
                    "6347be90ba0c920011ae21df",
                    "6347beecba0c920011ae22e7",
                    "6347bf95ba0c920011ae24b6",
                    "6347c087ba0c920011ae261d",
                    "6347c112ba0c920011ae2760",
                    "6347c1b0ba0c920011ae27d5",
                    "634937bcba0c920011ae9368",
                    "63493ca86904ba17f390ec95",
                    "634a750ec80192183c972c1e",
                    "634cfe40a96d1e00127956e2",
                    "634a784ab8593720978d8fb1",
                    "634e70d7367c2174d38d0842",
                    "634f99a432ea3e49443f1fa9",
                    "634f9f0432ea3e49443f2131",
                    "6350da56378f933190c08ae2"
                ],
                "denStar": 163,
                "lastSpin": "2022-10-21T18:30:00.000Z",
                "hammer": 31,
                "type": "USER",
                "clanOrOrgId": "61a4b94ccbec4b0012cb40e4"
            }
        ],
        "createdAt": "2022-10-13T07:25:38.405Z",
        "updatedAt": "2022-10-21T09:43:08.771Z",
        "__v": 3,
        "rePostDetails": [],
        "rePostDetailsFrom": []
    }
]