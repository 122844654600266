import React, { Component } from 'react';
import betIc from '../../../images/icon/bet-ic.png';
import betPeopleIc from '../../../images/icon/betPeople-ic.png';
import winCup from '../../../images/icon/winCup.png';
import { errorHandler, remainingTime, updateLoader } from '../../../utils/common.utils';
import { HiOutlineChevronLeft } from 'react-icons/hi';
import { Modal } from 'react-bootstrap';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import { checkBettingStatus, getBets, getPlayersLogoGrid } from '../../../utils/Betting.utils';
import { profile } from '../../../utils/localstorage.utils';
import BettingApis from '../../../helper/betting.api';
import { toast } from 'material-react-toastify';
import MyBets from '../pages/betting zone/MyBets';
import AllBets from '../pages/betting zone/AllBets';
import { createAndSendNotification } from '../../../utils/notification.utils';
import { getDateMonthYear } from '../../../utils/dateTime.utils';

export default class UpcomingBetsCard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            tournamentDetails: props.tournamentDetails,
            upcomingBets: [],
            matchDetails: props.matchDetails,
            BettingDetailsView: 'AllBets',
            selectedChoice: null,
            selectedChoiceDetails: null,
            selectedBettingStatement: null,
            myBets: null
        }

        this.bettingApis = new BettingApis();

        if(props.matchDetails){
            this.setUpcomingBets(props.matchDetails)
        }
        
        getBets(`?userId=${profile()?.id}&matchId=${props.matchDetails._id}`,this.setMyBetsDetails)
        
    }

    componentWillReceiveProps(newProps){
        if(newProps.matchDetails){
            this.setUpcomingBets(newProps.matchDetails)
        }
    }

    setUpcomingBets = (matchDetails) =>{
        var upcomingBets = [];

            if(matchDetails.matchBettingStatements){
                var statements = matchDetails.matchBettingStatements.map((statement,i)=>{
                    statement.matchSchedule = matchDetails.matchSchedule;
                    return statement;
                })
                upcomingBets.push(...statements)
            }

            upcomingBets = upcomingBets.filter((bet,i)=>{
                return bet.isPaid;
            });

        this.setState({
            upcomingBets: upcomingBets,
            matchDetails: matchDetails
        })
    }


    setMyBetsDetails = (response) =>{
        this.setState({
            myBets: response
        })
    }



    onConfirmBet = (e) =>{
        console.log("this.state.selectedBettingStatement ::",this.state.selectedBettingStatement?.bettingStatement?.statement)
        updateLoader(true);
        const data = {
            bettingStatementDetailsId: this.state.selectedBettingStatement._id,
            chosenOne: this.state.selectedChoice
        }
        

        this.bettingApis.createBet(data).then((res: AxiosResponse) => {
            console.log("res :: ",res);
            updateLoader(false);
            

            var notification = {
                type : "BETTING",
                heading : "Congratulations !",
                message :  `Your bet '${this.state.selectedBettingStatement?.bettingStatement?.statement}' on the match of Tournament '${this.state.tournamentDetails?.name}' is placed successfully. All the best for  your results.`,
                link : `/tournament/${this.state.tournamentDetails?._id}?page=BettingZone`
            };
            console.log("-----notification :: ",notification)
            createAndSendNotification(notification,[profile().id]);
            this.setState({
                selectedChoice: null,
                selectedChoiceDetails: null,
                selectedBettingStatement: null,
                betConfirmationModal: false,
                BettingDetailsView: 'MyBets'
            });

            toast.success("Bet has been added successfully");
            getBets(`?userId=${profile()?.id}&matchId=${this.state.matchDetails._id}`,this.setMyBetsDetails)
          
        }).catch((reason: AxiosError) => {
            updateLoader(false);
            errorHandler(reason);
        });

    }

    selectOption = (isListItem, choice, selectedChoiceDetails) =>{
        try{
            this.setState({
                selectedChoice: (isListItem ? choice.target?.value : choice),
                selectedChoiceDetails: selectedChoiceDetails
            });
        }catch(err){
            console.log("Error :: ",err)
        }

    }

    initiateBet = (bet) =>{
        if(this.state.selectedChoice === null || this.state.selectedChoice === undefined || bet?._id !== this.state.selectedChoiceDetails?._id){
            toast.warning( `Please select one of the options.`)
        }else{
            this.setState({
                selectedBettingStatement: bet,
                betConfirmationModal: true
            });    
        }
    }


    render() {

        var canAddBets = checkBettingStatus(this.state.matchDetails?.matchSchedule);
      console.log("this.state.matchDetails :: ",this.state.matchDetails)
       console.log("this.state.selectedChoiceDetails :: ",this.state.selectedChoiceDetails?.entryFees)
       return(
            <div className='container'>
                {/* Head Section */}
                <div className='row'>
                    <div className='col-12 mb-3'>
                      
                      
                        <div className='dark-gray-card border-0 text-center p-3'>
                            <div className='pseudo fs-14 fw-600 w-100' style={{ background: 'transparent' }}>
                            {canAddBets ? remainingTime(this.state.matchDetails?.matchSchedule) : getDateMonthYear(this.state.matchDetails?.matchSchedule)}
                                <span className="before" style={{ background: 'transparent' }}> </span>
                                <span className="after" style={{ background: 'transparent' }}> </span>
                            </div>
                            {this.state.tournamentDetails.competitionType === "FFA" ?
                                <div className='d-flex justify-content-center'>
                                  {
                                      getPlayersLogoGrid(this.state.matchDetails)
                                  }
                                </div>
                            :
                                this.state.tournamentDetails.participantType === "SINGLE" ?
                                    <div className='row'>
                                        <div className='col-5 text-center'>
                                            <img src={this.state.matchDetails.participants[0].profilePicture.url} width='40' height='40' alt='' className='rounded-circle' />
                                            <p className='fs-12 fw-700 text-white mb-2'>{this.state.matchDetails.participants[0].inGameName}</p>
                                        </div>
                                        <div className='col-2 text-center align-self-center'>
                                            <p className='fs-16 fw-700 text-white'>VS</p>
                                        </div>
                                        <div className='col-5 text-center'>
                                            <img src={this.state.matchDetails.participants[1].profilePicture.url} width='40' height='40' alt='' className='rounded-circle' />
                                            <p className='fs-12 fw-700 text-white mb-2'>{this.state.matchDetails.participants[1].inGameName}</p>
                                        </div>
                                    </div>
                                :
                                    <div className='row'>
                                        <div className='col-5 text-center'>
                                            <img src={this.state.matchDetails.participants[0].logo.url} width='40' height='40' alt='' className='rounded-circle' />
                                            <p className='fs-12 fw-700 text-white mb-2'>{this.state.matchDetails.participants[0].name}</p>
                                        </div>
                                        <div className='col-2 text-center align-self-center'>
                                            <p className='fs-16 fw-700 text-white'>VS</p>
                                        </div>
                                        <div className='col-5 text-center'>
                                            <img src={this.state.matchDetails.participants[1].logo.url} width='40' height='40' alt='' className='rounded-circle' />
                                            <p className='fs-12 fw-700 text-white mb-2'>{this.state.matchDetails.participants[1].name}</p>
                                        </div>
                                    </div>
                            }
                            
                            <div className='d-flex align-items-center justify-content-between px-3'>
                                <div className='d-flex'>
                                    <img src={betIc} width='16' height='16' alt='' />
                                    <p className='fs-12 fw-700 text-white-50 mb-0 ms-2'>Starting from <span className='lime-green-text'> {this.state.matchDetails?.startingFrom} EC </span></p>
                                </div>
                                <div className='d-flex'>
                                    <img src={betPeopleIc} width='16' height='16' alt='' />
                                    <p className='fs-12 fw-700 text-white-50 ms-2'>{this.state.matchDetails?.noOfBetters}</p>
                                </div>
                            </div>
                            <div className='d-flex align-items-center px-3 mb-1'>
                                <img src={winCup} width='16' height='16' alt='' />
                                <p className='fs-12 fw-700 text-white-50 mb-0 ms-2'>Cumilative total pool: <span className='light-yellow'> {this.state.matchDetails?.cumulativePool} EC </span></p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Switch for All and My Bets */}
                <div className='row'>
                    <div className='col-12'>
                        <div className='d-flex align-items-center mx-2'>
                            <HiOutlineChevronLeft className='fs-30 text-white' onClick={(e) => this.props.switchToBets()} />
                            <div className='m-auto'>
                                <button className={` ${this.state.BettingDetailsView === 'AllBets' ? 'clementine-flat-btn' : 'dim_gray-flat-btn'} fs-14 fw-500 px-3 py-1 rounded rounded-2 border-0 text-white me-2`} type="submit" onClick={() => { this.setState({ BettingDetailsView: 'AllBets' }) }}>
                                    All Bets
                                </button>
                                <button className={`${this.state.BettingDetailsView === 'MyBets' ? 'clementine-flat-btn' : 'dim_gray-flat-btn'} fs-14 fw-500 px-3 py-1 rounded rounded-2 border-0 text-white ms-2`} type="submit" onClick={() => { this.setState({ BettingDetailsView: 'MyBets' }) }}>
                                    My Bets
                                </button>
                            </div>
                        </div>

                    </div>
                </div>

                {(this.state.BettingDetailsView === 'AllBets') ?
                    <AllBets matchDetails={this.state.matchDetails} tournamentDetails={this.state.tournamentDetails} selectedChoice={this.state.selectedChoice} selectedChoiceDetails={this.state.selectedChoiceDetails} selectOption={this.selectOption} initiateBet={this.initiateBet} />
                    :
                    <MyBets betsDetails={this.state.myBets} competitionType={this.state.tournamentDetails.competitionType} addRow={true} />
                }

                <Modal show={this.state.betConfirmationModal} centered dialogClassName="Confirmation-modal">
                    <Modal.Body>
                        <div className='d-flex flex-column p-3'>
                            <h1 className='fs-20 fw-600 text-white text-center'>Confirm</h1>
                            <IoIosCloseCircleOutline className="close-icon position-absolute" style={{ right: '15px', top: '15px' }} onClick={() => this.setState({ betConfirmationModal: false })} />
                            <p className='fs-14 fw-600 text-white-50 text-center my-2' style={{ textAlign: 'justify' }}>Are you sure you want to bet with <br /> {this.state.selectedChoiceDetails?.entryFees} EC?</p>
                            <div className='d-flex align-self-center'>
                                <button className='white-flat-btn fw-bold py-2 px-4 me-2 mt-3' onClick={(e)=>this.onConfirmBet(e)}>Yes</button>
                                <button className='white-flat-btn fw-bold py-2 px-4 ms-2 mt-3' onClick={() => this.setState({ betConfirmationModal: false })}>Close</button>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
        
       
        
    }
}