import { toast } from "material-react-toastify";
import { AxiosResponse, AxiosError } from "axios";
import TournamentApis from "../helper/tournament.api";
import ClanApis from "../helper/clan.api";
import AuthApis from "../helper/auth.api";
import OrganizationApis from "../helper/organization.api";
import CommonApis from "../helper/common.api";
import MatchesApis from "../helper/matches.api";
import { setUpdatedEc } from "../redux/actions/commonActions";
//images
import Valorant from "../images/score/ValorantScore.png";
import PUBG from "../images/score/PUBGScore.png";
import LOL from "../images/score/LOLScore.png";
import FreeFire from "../images/score/FreeFireScore.png";
import Fortnite from "../images/score/FortniteScore.png";
import Dota2 from "../images/score/Dota2Score.png";
import CSGO from "../images/score/CSGOScore.png";
import COD from "../images/score/CODScore.png";
import BGMI from "../images/score/BGMIScore.png";
import ApexLegends from "../images/score/ApexLegendScore.png";
import { ASSETS, DEN } from "./Strings.utils";
import { profile } from "./localstorage.utils";
import {
  COMMENTS_RANKS_ARRAY,
  ParticipantType,
  RANKS_ARRAY,
  SPOTLIGHT_RANKS_ARRAY,
} from "./enums.utils";
import {
  COMMISSION_TYPES,
  COMMISSION_QUESTION_TYPE,
  ListingType,
} from "./enums.utils";

const tournamentApis = new TournamentApis();
const commonApi = new CommonApis();
const matchApis = new MatchesApis();
const authApis = new AuthApis();
const clanApis = new ClanApis();
const organizationApis = new OrganizationApis();
var showLoader;
var getBackToMatches;
var closeRegistration;
var redirectURL = null;
var websiteData;
var adminData;

export const backgroundColors = [
  "#145DA0",
  "#05445E",
  "#2F5233",
  "#620ff0",
  "#050A30",
  "#000",
];
export const textColors = [
  "#B1D4E0",
  "#D4F1F4",
  "#B1D8B7",
  "#cfb5fa",
  "#68BBE3",
  "#858585",
];
export const secondaryTextColors = [
  "#2E8BC0",
  "#189AB4",
  "#76B947",
  "#9a65f6",
  "#055C9D",
  "#404040",
];
export const regions = [
  "Asia",
  "UAE",
  "North America",
  "South America",
  "Europe",
];
export const streamingPlatforms = [
  "facebook",
  "youtube",
  "twitch",
  "loco",
  "rooter",
  "others",
];
export const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const defaultPreviewImage =
  "https://espotz-webapp-images.s3.ap-south-1.amazonaws.com/assets/League_of_legends.png";
export const SponsorTypes = [
  "Powered By",
  "Sponsored By",
  "Co-Powered By",
  "Co-sponsored By",
  "Associate Sponsor",
  "Streaming Partner",
];
export const line = [
  0, 1, 2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048, 4096, 8192, 16384,
  32768,
];
export const structures = [
  0, 40, 120, 280, 600, 1240, 2520, 5080, 10200, 20440, 40920, 81880, 163800,
];
export const mandatoryRules = [
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
  "Integer sit amet odio aliquet, rhoncus augue et, semper nibh.",
  "Donec ac tellus vel magna iaculis pretium",
  "Pellentesque id ligula rhoncus, consectetur lacus id, vulputate mi",
  "Cras posuere tellus at velit dictum, eget ornare nisl dictum.",
];

export const ellipsis = (type, limit) => {
  type = String(type);
  if (type?.length && type?.length > limit) {
    let shortname = type.substring(0, limit) + " ...";
    return shortname;
  }
  return type;
};

export const errorHandler = (reason, logout?) => {
  console.log("reason :: ", reason);
  if (reason?.response?.status === 400 || reason?.response?.status === 422) {
    toast.error(reason.response.data.error);
  } else if (reason?.response?.status === 401) {
    toast.error("Your session has been expired, please log in again");
    if (logout) {
      logout(true);
    }
  } else {
    toast.error(reason.message);
  }
};

export const capitalize = (s) => {
  return s && s[0].toUpperCase() + s.slice(1);
};

export const PlayoffFormats = {
  "Single Match": 1,
  "Best of 3": 3,
  "Best of 5": 5,
  "Best of 7": 7,
  "Best of 9": 9,
};

export const getTournamentData = async (id, setData) => {
  const data = {
    _id: id,
  };
  await tournamentApis
    .getTournaments(data)
    .then((res: AxiosResponse) => {
      if (res.data.result?.length) {
        setData(res.data.result[0]);
        return false;
      }
      setData(null);
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
      setData(null);
    });
};

export const getTournamentAdditionalData = async (id, setData) => {
  updateLoader(true);
  const data = {
    ids: [id],
  };
  await tournamentApis
    .getMultipleTournaments(data)
    .then((res: AxiosResponse) => {
      if (res.data.result?.length) {
        setData(res.data.result[0]);
        updateLoader(false);
        return false;
      }
      setData(null);
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
      updateLoader(false);
      setData(null);
    });
};

export const uploadSingleImgCommon = async (data, setData) => {
  commonApi
    .uploadSingleImg(data)
    .then((res: AxiosResponse) => {
      if (res.data.result) {
        setData(res.data.result);
      }
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
      setData(null);
    });
};

export const calculateMatches = (
  num,
  threshold,
  pop?,
  include?,
  isAddOrUpdate?
) => {
  var stages = 1;
  var arr = [num];
  while (num !== 1) {
    num = num / 2;
    if (include && num === 1 && isAddOrUpdate) {
      arr.push(2);
    } else {
      arr.push(num);
    }
    stages++;
    if (num === threshold) break;
  }
  if (pop) {
    arr.pop();
  }

  return arr;
};

const calculateMatches2 = (num, threshold, numOfGroups) => {
  var stages = 1;
  var arr = [num];
  if (num * numOfGroups === threshold) {
    return arr;
  }
  while (num !== 1) {
    num = num / 2;
    arr.push(num);

    stages++;

    if (num * numOfGroups === threshold) {
      console.log(numOfGroups + ":: num = ", num);
      break;
    }
    // if (((num * numOfGroups) === (threshold * 2)) || (num === threshold)) {
    //     if ((threshold * 2) === numOfGroups) {
    //         arr.push(1);
    //     }
    //     break;
    // }
  }
  return arr;
};

export const getMatchesStructure = (stageDetails, key) => {
  console.log("stageDetails : ", stageDetails);
  switch (key) {
    case 1: // Duel Single Elimination
      var pairs = stageDetails.maxParticipants / 2;
      var threshold = stageDetails.qualificationThreshold / 2;
      const pop =
        !stageDetails.includeThirdPlace &&
        stageDetails.qualificationThreshold !== 1
          ? true
          : false;

      var structure = calculateMatches(
        pairs,
        threshold,
        pop,
        stageDetails.includeThirdPlace,
        true
      );
      var groups = [{}];

      structure.forEach((value, index) => {
        groups[0][index + 1] = value;
      });

      return groups;
    case 2: // Duel Double Elimination
      var pairs = (pairs = stageDetails.maxParticipants / 2);
      var threshold = stageDetails.qualificationThreshold / 2;
      var structure = calculateMatches(pairs, threshold);
      var groups = [{}, {}];
      console.log("!!!!! structure2 :: ", structure2);
      var winningStructure;
      if (stageDetails.enabledGrandFinal === "NONE") {
        winningStructure = structure;
      } else if (stageDetails.enabledGrandFinal === "SIMPLE") {
        winningStructure = [...structure, 1];
      } else {
        winningStructure = [...structure, 1, 1];
      }

      console.log("winningStructure : ", winningStructure);

      winningStructure.forEach((value, index) => {
        groups[0][index + 1] = value;
      });

      structure.shift();

      var structure2 = [];
      structure.forEach((val, i) => {
        structure2.push(val);
        structure2.push(val);
      });
      console.log("!!!!! structure2 :: ", structure2);

      structure2.forEach((value, index) => {
        groups[1][index + 1] = value;
      });

      return groups;
    case 3: // Duel Round Robin
      var groups = [];

      for (let i = 0; i < stageDetails.maxNumberOfGroups; i++) {
        var obj = {};
        for (let j = 0; j < stageDetails.maxParticipantsPerGroup - 1; j++) {
          obj[j + 1] = stageDetails.maxParticipantsPerGroup / 2;
        }

        if (
          stageDetails.roundRobinType === "DOUBLE" ||
          stageDetails.roundRobinType === "TRIPLE"
        ) {
          let lastKey = Object.keys(obj).pop();
          let lastValue = obj[Object.keys(obj).pop()];

          var start = parseInt(lastKey) + 1;
          var end =
            lastKey * (stageDetails.roundRobinType === "DOUBLE" ? 2 : 3);

          for (var k = start; k <= end; k++) {
            obj[k] = lastValue;
          }
        }

        groups.push(obj);
      }

      return groups;
    case 4: // Duel Bracket Groups
      var pairs = stageDetails.maxParticipantsPerGroup / 2;
      var threshold = stageDetails.qualificationThreshold / 2;
      var structure;
      if (stageDetails.bracketType === "SINGLE") {
        structure = calculateMatches2(
          pairs,
          stageDetails.qualificationThreshold,
          stageDetails.maxNumberOfGroups
        );
      } else {
        structure = calculateMatches(pairs, threshold);
      }
      var groups = [];
      for (let i = 0; i < stageDetails.maxNumberOfGroups; i++) {
        var obj = {};
        for (let j = 0; j < stageDetails.maxParticipantsPerGroup - 1; j++) {
          structure.forEach((value, index) => {
            obj[index + 1] = value;
          });
        }
        groups.push(obj);
      }

      if (stageDetails.bracketType === "DOUBLE") {
        structure.shift();
        groups.forEach((object, index) => {
          var structure2 = [];
          structure.forEach((val) => {
            structure2.push(val);
            structure2.push(val);
          });
          console.log("structure2 :: ", structure2);
          var obj = {};
          structure2.forEach((ele, index) => {
            obj[index + 1] = ele;
          });
          var groupsArray = [object, obj];
          groups[index] = groupsArray;
        });
      }

      return groups;
    case 5: // FFA Single Elimination
      var obj = {};
      var counter = 1;
      var numOfMatches =
        stageDetails.maxParticipants / stageDetails.maxParticipantsPerMatch;
      do {
        var prevNumOfMatches = numOfMatches;
        var matchCount = Math.ceil(numOfMatches);
        obj[counter] = matchCount;
        console.log(
          `(${matchCount} * ${stageDetails.winnersPerMatch}) === ${stageDetails.qualificationThreshold}`
        );
        if (
          matchCount * stageDetails.winnersPerMatch ===
          stageDetails.qualificationThreshold
        ) {
          break;
        }

        var winnersAfterRound = numOfMatches * stageDetails.winnersPerMatch;
        numOfMatches = winnersAfterRound / stageDetails.maxParticipantsPerMatch;

        counter++;
        if (numOfMatches < 1 && prevNumOfMatches != 1) {
          obj[counter] = 1;
          break;
        } else if (prevNumOfMatches == 1) {
          break;
        }
      } while (numOfMatches >= 1);

      return [obj];
    case 6: // FFA Simple Stage
      var groups = [{}];
      groups[0][1] = parseInt(stageDetails.numberOfMatches);

      return groups;
    case 7: // FFA Bracket Groups
      var perGroupMembers =
        stageDetails.maxParticipants / stageDetails.maxNumberOfGroups;
      var perGroupNumberOfMatches =
        perGroupMembers / stageDetails.maxParticipantsPerMatch;

      var structure = [perGroupNumberOfMatches];
      var count = 0;
      if (
        perGroupNumberOfMatches *
          stageDetails.winnersPerMatch *
          stageDetails.maxNumberOfGroups !==
        stageDetails.qualificationThreshold
      ) {
        do {
          perGroupNumberOfMatches =
            (perGroupNumberOfMatches * stageDetails.winnersPerMatch) /
            stageDetails.maxParticipantsPerMatch;
          if (perGroupNumberOfMatches < 1) {
            break;
          } else {
            structure.push(perGroupNumberOfMatches);

            count++;
          }
        } while (
          perGroupNumberOfMatches *
            stageDetails.winnersPerMatch *
            stageDetails.maxNumberOfGroups !==
          stageDetails.qualificationThreshold
        );
      }

      var groups = [];

      for (let i = 0; i < stageDetails.maxNumberOfGroups; i++) {
        var obj = {};
        for (let j = 0; j < stageDetails.maxParticipantsPerGroup - 1; j++) {
          structure.forEach((value, index) => {
            obj[index + 1] = parseInt(value);
          });
        }
        groups.push(obj);
      }

      return groups;
    default: // Flexible Stages
      var groups = [{ 1: stageDetails?.numberOfMatches }];

      return groups;
  }
};

export const getMonthAndDate = (dateString) => {
  try {
    const matchSchedule = new Date(dateString);
    var hours = matchSchedule.getHours();
    var minutes = matchSchedule.getMinutes();
    var min = minutes < 10 ? `0${minutes}` : minutes;
    var ampm = hours >= 12 ? "PM" : "AM";

    hours = hours > 12 ? hours - 12 : hours;
    return [
      `${months[matchSchedule.getMonth()]} ${matchSchedule.getDate()}`,
      `${hours}:${min} ${ampm}`,
    ];
  } catch (error) {
    console.log("Error : ", error);
    return [`TBD`, `TBD`];
  }
};
export const remainingTime = (dateString) => {
  try {
    const today = new Date();
    const endDate = new Date(dateString);
    let diffMs = endDate - today;
    var diffDays = Math.floor(diffMs / 86400000); // days
    if (diffDays >= 0) {
      var diffHrs = Math.floor((diffMs % 86400000) / 3600000) + 24 * diffDays; // hours
      var diffMins = Math.round(((diffMs % 86400000) % 3600000) / 60000); // minutes
      return [`${diffHrs} hr`, ` ${diffMins} min`];
    } else {
      return ["", ""];
    }
  } catch (error) {
    console.log("error::", error);
    return [`TBD`, `TBD`];
  }
};

export const dateFormat = (dateString) => {
  if (dateString === undefined) {
    return "Pick a date";
  } else {
    try {
      const givenDate = new Date(dateString);
      const dataArray = givenDate.toString().split(" ");
      const time = givenDate.toLocaleTimeString();
      return `${dataArray[2]} ${dataArray[1]} ${dataArray[3]}, ${time}`;
    } catch (error) {
      console.log("error::", error);
      return "Select Date";
    }
  }
};

export const  formatLocalDateTime = (dateString) =>{
  const date = new Date(dateString); // Parse the UTC date string
  const tzOffset = date.getTimezoneOffset() * 60000; // Get the timezone offset in milliseconds
  const localDate = new Date(date.getTime() - tzOffset); // Adjust for timezone

  // Convert to "YYYY-MM-DDTHH:MM" format
  return localDate.toISOString().slice(0, 16);
}

export const showHideMatchInvite = (match, tournament?) => {
  // console.log('match::', match);
  var time = tournament.registrationEndTime;
  var matchTime = new Date(time);
  // console.log('Match Schedule ::', matchTime);
  var currentTime = new Date();
  // console.log('Current Time ::', currentTime);
  var timeDifference = matchTime - currentTime;
  // console.log('time difference ::', timeDifference);
  if (timeDifference > 0 && !match?.resultAdded) {
    return true;
  } else {
    return false;
  }
};

export const disableSwap = (match) => {
  // console.log('match::', match);
  var time = match.matchSchedule;
  var matchTime = new Date(time);
  // console.log('Match Schedule ::', matchTime);
  var currentTime = new Date();
  // console.log('Current Time ::', currentTime);
  var timeDifference = matchTime - currentTime;
  // console.log('time difference ::', timeDifference);
  if (timeDifference < 0 || match?.resultAdded) {
    return true;
  } else {
    return false;
  }
};

export const getMatchAndParticipantsDetails = async (stages, setMatchData) => {
  var matches = [];
  stages.groups.map((group, groupIndex) => {
    Object.keys(group).map((key, roundIndex) => {
      group[key].map((matchId) => {
        matches.push(matchId);
      });
    });
  });

  var data = { ids: matches };
  updateLoader(true);
  await matchApis
    .getMultipleMatches(data)
    .then((res: AxiosResponse) => {
      var response = res.data["result"];

      stages.groups.map((group, groupIndex) => {
        Object.keys(group).map((key, roundIndex) => {
          group[key].map((matchId, matchIndex) => {
            //matches.push(matchId)
            let matchDetails = response.filter((match) => {
              return matchId == match._id;
            });

            //console.log(`### 230 ### ${matchId} :: `,matchDetails[0]);
            stages.groups[groupIndex][key][matchIndex] = matchDetails[0]
              ? matchDetails[0]
              : matchId;
            //matchId =matchDetails;
          });
        });
      });

      updateLoader(false);
      setMatchData(stages);
    })
    .catch((err: AxiosError) => {
      errorHandler(err);
      updateLoader(false);
      setMatchData(null);
    });
};

export const getMatchAndParticipantsDetails2 = async (
  stagesArray,
  setMatchData
) => {
  try {
    await stagesArray.forEach((stage, stagesArrayIndex) => {
      var matches = [];
      stage.groups.map((group, groupIndex) => {
        Object.keys(group).map((key, roundIndex) => {
          group[key].map((matchId) => {
            matches.push(matchId);
          });
        });
      });

      var data = { ids: matches };

      matchApis
        .getMultipleMatches(data)
        .then((res: AxiosResponse) => {
          var response = res.data["result"];

          stage.groups.map((group, groupIndex) => {
            Object.keys(group).map((key, roundIndex) => {
              group[key].map((matchId, matchIndex) => {
                let matchDetails = response.filter((match) => {
                  return matchId == match._id;
                });

                stage.groups[groupIndex][key][matchIndex] = matchDetails[0]
                  ? matchDetails[0]
                  : matchId;
              });
            });
          });

          stagesArray[stagesArrayIndex] = stage;
        })
        .catch((err: AxiosError) => {
          errorHandler(err);
        });
    });
    setMatchData(stagesArray);
  } catch (err) {
    errorHandler(err);
    setMatchData(null);
  }
};

export const getMatchAndParticipantsDetailsForBracketsDoubleElimination =
  async (tournamentId, setMatchData, stageIndex, stages) => {
    //stagesArray

    await matchApis
      .getStageDetails(tournamentId, stageIndex)
      .then((res: AxiosResponse) => {
        var response = res.data["result"];
        //    console.log("---response :: ", response)
        stages[stageIndex] = response;
        setMatchData(stages);
        updateLoader(false);
      })
      .catch((err: AxiosError) => {
        errorHandler(err);
        updateLoader(false);
        setMatchData(null);
      });
  };

export const getMatchAndParticipantsDetails3 = async (
  stagesArray,
  setMatchData,
  stageIndex
) => {
  updateLoader(true);
  try {
    await stagesArray.forEach((stage, stagesArrayIndex) => {
      if (stageIndex == stagesArrayIndex) {
        var matches = [];
        stage.groups.map((group, groupIndex) => {
          Object.keys(group).map((key, roundIndex) => {
            group[key].map((matchId) => {
              matches.push(matchId);
            });
          });
        });

        var data = { ids: matches };

        matchApis
          .getMultipleMatches(data)
          .then((res: AxiosResponse) => {
            var response = res.data["result"];

            stage.groups.map((group, groupIndex) => {
              Object.keys(group).map((key, roundIndex) => {
                group[key].map((matchId, matchIndex) => {
                  let matchDetails = response.filter((match) => {
                    return matchId == match._id;
                  });

                  stage.groups[groupIndex][key][matchIndex] = matchDetails[0]
                    ? matchDetails[0]
                    : matchId;
                });
              });
            });

            stagesArray[stagesArrayIndex] = stage;
            updateLoader(false);
            setMatchData(stagesArray);
          })
          .catch((err: AxiosError) => {
            updateLoader(false);
            setMatchData(stagesArray);
            errorHandler(err);
          });
      }
    });
  } catch (err) {
    errorHandler(err);
    setMatchData(null);
  }
};

export const generateRoundRobinGroups = (
  maxParticipants,
  numberOfGroups,
  playoffFormat
) => {
  try {
    var groups = maxParticipants / maxParticipantPerGroup;
    var maxParticipantPerGroup = 4;

    var groupsArray = [];

    [...Array(groups)].map((ele, index) => {
      var obj = {};
      [...Array(playoffFormat)].map((e, i) => {
        obj[i + 1] = maxParticipantPerGroup;
      });

      groupsArray.push(obj);
    });
    console.log("groupsArray :: ", groupsArray);
    return [];
  } catch (error) {
    console.log("error::", error);
    return [];
  }
};

export const getMarginTopForRounds = (index, prevValue) => {
  var marginTop = 0;
  switch (index) {
    case 0:
      marginTop = 0;
      break;
    case 1:
      marginTop = 35;
      break;
    case 2:
      marginTop = 105;
      break;
    default:
      marginTop = prevValue * 2 + 35;
      break;
  }
  return marginTop;
};

export const saveLoader = (updateLoader) => {
  showLoader = updateLoader;
};

export const saveGetBackToMatches = (goToMatches) => {
  getBackToMatches = goToMatches;
};

export const saveCloseRegistration = (closeRegistrationScreen) => {
  closeRegistration = closeRegistrationScreen;
};

export const closeRegistrationScreen = () => {
  closeRegistration();
};

export const updateLoader = (val) => {
  if (showLoader) {
    showLoader(val);
  }
};

export const backToMatches = (tab, index) => {
  getBackToMatches(tab, index);
};

export const setRedirectURL = (url) => {
  console.log("Setting Redirect URL :: ", url);
  redirectURL = url;
};

export const DisputeStatus = {
  DISPUTERAISED: "DISPUTE_RAISED",
  REJECTED: "REJECTED",
  RESOLVED: "RESOLVED",
};

export const is_Natural = (n) => {
  if (typeof n !== "number") return "Not a number";

  return n >= 0.0 && Math.floor(n) === n && n !== Infinity;
};

// Will return structures count in following sequence :: [0,40,120,280,600,1240,2520,5080,10200,20440,40920,81880,163800,...]
export const getStructureCount = (index) => {
  var finalCount = 0;

  if (index > 0) {
    for (let i = 0; i < index; i++) {
      finalCount = finalCount * 2 + 40;
    }
  }

  return finalCount;
};

// Will return line structures length in following sequence :: [0,1,2,4,8,16,32,64,128,256,512,1024,2048,4096,8192,16384,32768,...]
export const getStructuresLineCount = (index) => {
  var finalLineCount = 0;

  if (index > 0) {
    if (index === 1) {
      return 1;
    }
    finalLineCount = 1;
    for (let i = 0; i < index - 1; i++) {
      finalLineCount = finalLineCount * 2;
    }
  }

  return finalLineCount;
};

export const toUTC = (date) => {
  var date = new Date();
  var now_utc = Date.UTC(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );

  return new Date(now_utc);
};

export const convertUTCDateToLocalDate = (date) => {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds()
    )
  );
};

export const convertLocalDatetoUTCDate = (date) => {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds()
  );
};

export const getOnlyDate = (date) => {
  const options = { dateStyle: "short" };
  return date.toLocaleString("en", options);
};

export const zoomBrackets = (key, scale, maxWidth, setZoomState) => {
  switch (key) {
    case "In":
      var scale = scale * 1.1;
      setZoomState(scale, maxWidth);
      break;
    case "Out":
      if (scale <= 1) {
        maxWidth = maxWidth + 10 / scale;
      } else {
        maxWidth = maxWidth + 10 * scale;
      }
      var scale = scale / 1.1;
      setZoomState(scale, maxWidth);
      break;
    default:
      setZoomState(1, 100);
      break;
  }
};

export const linkWithDiscord = () => {
  return {
    msg: (
      <p className="fs-14 fw-600 text-center text-white">
        You haven't connected to discord,click yes to connect
      </p>
    ),
    key: "",
  };
};

// To fetch country codes for contact details
export const loadCountryCodes = (setCountryCodes) => {
  updateLoader(true);
  commonApi.getCountryCodes().then((res: AxiosResponse) => {
      updateLoader(false);
      setCountryCodes(res.data.result);
  }).catch((err: AxiosError) => {
      updateLoader(false);
      errorHandler(err);
  });
};

export const defaultLogo = {
  name: "file",
  size: 273555,
  type: "image/jpeg",
  url: "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/4c75e240-cc65-11ec-b1e1-174cb1bc8e80",
};

export const defaultCover = {
  name: "file",
  size: 386594,
  type: "image/jpeg",
  url: "https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/images/7bedd4b0-cc65-11ec-b1e1-174cb1bc8e80",
};

export const mapStateToPropsEC = (props) => {
  return {
    UpdatedEc: props.UpdateEc,
  };
};
export const mapDispatchToPropsEC = (dispatch) => {
  return {
    dispatchData: (data) => dispatch(setUpdatedEc(data)),
  };
};

export const scoreCardGameURL = (name) => {
  //console.log('GAME NAME --------------', name);
  switch (name) {
    case "Fortnite": //Fortnite
      return Fortnite;
    case "League Of Legends": //LOL
      return LOL;
    case "Dota 2": //DOTA-2
      return Dota2;
    case "CS:GO": //CS:GO
      return CSGO;
    case "PUBG": //PUBG
      return PUBG;
    case "Valorant": //Valorant
      return Valorant;
    case "PUBG Mobile": //BGMI
      return BGMI;
    case "Call Of Duty": //COD
      return COD;
    case "FreeFire": //FreeFire
      return FreeFire;
    case "Apex Legends": //Apex Legends
      return ApexLegends;
    default:
      return PUBG;
  }
};
export const isValidString = (str) => {
  if (str === null) {
    return false;
  }
  if (!str || !str.trim()) {
    return false;
  }
  return true;
};

export const getHeading = (details) => {
  const gameName = details?.gameDetails?.name;
  const competitionType = details?.competitionType;
  const participantType = details?.participantType;
  const participantsPerTeam = details.participantsPerTeam;
  if (competitionType === "DUEL" && participantType === "SINGLE") {
    return `${gameName} SOLO ${competitionType}`;
  }
  if (competitionType === "FFA") {
    return `${gameName} ${participantType} ${competitionType}`;
  } else {
    return `${gameName} ${competitionType} ${participantsPerTeam} VS ${participantsPerTeam}`;
  }
};

export const getAvatarForLetter = (letter) => {
  return `https://espotz-dev-assets.s3.ap-south-1.amazonaws.com/profile-letters/letter-${letter.toLowerCase()}.png`;
};

// To show only first 3 letter's and then encrypt rest character's with ***
export const publicPrivateEncryptedString = (string) => {
  string = string.toString();
  let result = string.slice(0, 3);
  for (let i = 0; i < string.length - 3; i++) {
    result += "*";
  }
  return result;
};

export const roundOffNumber = (number) => {
  console.log("rounding off number ::", Math.round(number));
  switch (true) {
    case Math.round(number) <= 1000:
      return number;
    case Math.round(number) > 1000:
      let strNumber = Math.round(number).toString();
      console.log("string number ::", strNumber);
      return `${Math.floor(strNumber / 1000)}k+`;
    default:
      break;
  }
};

export const getMembersIds = (type, members) => {
  var result = [];
  try {
    Object.keys(members).map((key, i) => {
      if (key !== "captain" && key !== "creator") {
        if (typeof members[key] === "string" && key !== "captain") {
          if (members[key] !== profile().id) {
            result.push(members[key]);
          }
        } else {
          members[key].forEach((user, i) => {
            if (type === "team") {
              if (user.userId !== profile().id) {
                result.push(user.userId);
              }
            } else {
              if (user.id !== profile().id) {
                result.push(user.id);
              }
            }
          });
        }
      }
    });
  } catch (err) {
    console.log("Error :: ", err);
  }

  return result;
};

export const getAllMembersIdFromClan = (clanMembers, teamsDetails) => {
  var result = [];
  try {
    delete clanMembers.creator;

    Object.keys(clanMembers).map((key, i) => {
      clanMembers[key].forEach((user, i) => {
        result.push(user.id);
      });
    });

    teamsDetails?.forEach((team, i) => {
      var members = team.members;
      delete members.captain;
      Object.keys(members).map((key, i) => {
        members[key].forEach((user, i) => {
          try {
            if (user.id !== profile().id) {
              result.push(user.id);
            }
          } catch (err) {
            console.log("Error :: ", err);
          }
        });
      });
    });
  } catch (err) {
    console.error(err);
  }

  return [...new Set(result)].filter(Boolean);
};

export const getAllMembersIdFromTeam = (members) => {
  var result = [];
  delete members.captain;
  Object.keys(members).map((key, i) => {
    members[key].forEach((user, i) => {
      try {
        if (typeof user === "string") {
          if (user !== profile().id) {
            result.push(user);
          }
        } else if (user.id !== profile().id) {
          result.push(user.id);
        }
      } catch (err) {
        console.log("Error :: ", err);
      }
    });
  });
  return [...new Set(result)];
};

export const getParticipantsIds = (participantType, participants) => {
  var result = [];
  if (participantType === ParticipantType.SINGLE) {
    participants.forEach((players, i) => {
      if (players.id !== profile().id) {
        result.push(players.id);
      }
    });
  } else {
    participants.forEach((team, i) => {
      var arr = getAllMembersIdFromTeam(team.members);
      result = result.concat(arr);
    });
  }

  return result;
};

export const queryParams = (params) => {
  return Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");
};

export const isExists = (data) => {
  if (data !== null && data !== undefined) {
    return true;
  } else {
    return false;
  }
};

export const getWebsiteData = async () => {
  updateLoader(true);
  await commonApi
    .getWebData1()
    .then((res: AxiosResponse) => {
      updateLoader(false);
      websiteData = getImformationDetails(res.data.result);
      //console.log("getImformationDetails :: ",websiteData)

      //console.log('websiteData ::', websiteData);
    })
    .catch((err: AxiosError) => {
      updateLoader(false);
      errorHandler(err);
    });
};

export const getWebData = () => {
  if (websiteData) {
    return websiteData;
  }
};

export const getTournamentCancellationReasons = async (setReasons) => {
  updateLoader(true);
  await commonApi
    .getCancelReasons()
    .then((res: AxiosResponse) => {
      updateLoader(false);
      setReasons(res.data.result);
    })
    .catch((err: AxiosError) => {
      updateLoader(false);
      errorHandler(err);
    });
};

export const getImformationDetails = (data) => {
  try {
    let types = data.map((a) => a.type);
    types = [...new Set(types)];
    var finalResult = {};
    types.forEach((type, i) => {
      finalResult[type] = {};
      var subTypes = data.filter((obj) => obj.type === type);

      subTypes.forEach((obj, j) => {
        if (type === "NEWS") {
          finalResult[type] = obj.NEWS;
        } else {
          if (obj.subType && obj.subType === "FAQS") {
            finalResult[type][obj.subType] = obj.FAQ;
          } else {
            finalResult[type][obj.subType ? obj.subType : "DETAILS"] =
              obj.questions;
          }
        }
      });
    });
    return finalResult;
  } catch (err) {
    return null;
  }
};

//Get Admin Data
export const getAdminSideData = async () => {
  updateLoader(true);
  await commonApi
    .getAdminData()
    .then((res: AxiosResponse) => {
      updateLoader(false);
      adminData = res.data.result;
      //console.log("admin data :: ",adminData)
    })
    .catch((err: AxiosError) => {
      updateLoader(false);
      errorHandler(err);
    });
};

export const getAdminData = () => {
  if (adminData) {
    return adminData;
  }
};
//Get game rules by name
export const getGameRules = (name) => {
  try {
    const gameRules = adminData.rules?.filter((game) => game.game === name);
    return gameRules;
  } catch (err) {
    return null;
  }
};
//Get Terms and Conditions by type;
export const getTermsAndConditionsByType = (type) => {
  try {
    const terms = adminData.termsAndConditions?.filter(
      (val) => val.type === type
    );
    return terms[0];
  } catch (err) {
    return null;
  }
};

export const getCommissionByType = (type) => {
  try {
    const terms = adminData.commissions?.filter((val) => val.type === type);
    return terms[0];
  } catch (err) {
    return null;
  }
};

export const getDenDetailsByType = (type) => {
  try {
    const terms = adminData?.den?.filter((val) => val.type === type);
    return terms[0];
  } catch (err) {
    return null;
  }
};

export const getCancelTournamentCommission = (tournamentDetails) => {
  //1. get Listing Type of Tournament.
  const listingType = tournamentDetails?.entryFeeAndPrizeDetails?.listingType;

  //2. get Reg start time and Tour start time.
  const currentDate = new Date();
  const regStartDate = new Date(tournamentDetails?.registrationStartTime);
  const tourStartDate = new Date(tournamentDetails?.tournamentStartTime);
  const isRegular = listingType === ListingType.REGULAR ? true : false;
  const pastRegStartTime = currentDate >= regStartDate ? true : false;
  const pastTourStartTime = currentDate >= tourStartDate ? true : false;
  let cancelTOurCommissions = getCommissionByType(
    COMMISSION_TYPES.TOURNAMENT_CANCELLATION
  );
  //3. select in which category does the tournament fall and get the refund percentage.
  switch (true) {
    case isRegular && !pastRegStartTime:
      //regular before reg start time
      return cancelTOurCommissions.questions[
        COMMISSION_QUESTION_TYPE.REGULAR_BEFORE_RS
      ];
    case isRegular && pastRegStartTime && !pastTourStartTime:
      //regular between reg start time and tour start time
      return cancelTOurCommissions.questions[
        COMMISSION_QUESTION_TYPE.REGULAR_BW_RS_TS
      ];
    case isRegular && pastTourStartTime:
      //regular after tour start time
      return cancelTOurCommissions.questions[
        COMMISSION_QUESTION_TYPE.REGULAR_ATFER_TS
      ];
    case !isRegular && !pastRegStartTime:
      //premium before reg start time
      return cancelTOurCommissions.questions[
        COMMISSION_QUESTION_TYPE.PREMIUM_BEFORE_RS
      ];
    case !isRegular && pastRegStartTime && !pastTourStartTime:
      //premium between reg start time and tour start time
      return cancelTOurCommissions.questions[
        COMMISSION_QUESTION_TYPE.PREMIUM_BW_RS_TS
      ];
    case !isRegular && pastTourStartTime:
      //premium after tour start time
      return cancelTOurCommissions.questions[
        COMMISSION_QUESTION_TYPE.PREMIUM_ATFER_TS
      ];
    default:
      return cancelTOurCommissions.questions[
        COMMISSION_QUESTION_TYPE.REGULAR_BEFORE_RS
      ];
  }
};

export const getDetailsObj = (denProfile, setDetailsObj) => {
  if (denProfile) {
    const clanOrOrgId = denProfile.clanOrOrgId;
    const data = { _id: clanOrOrgId };
    switch (denProfile.type) {
      case DEN.SEARCH.TYPES.USER:
        updateLoader(true);
        authApis
          .getProfile(clanOrOrgId)
          .then((res: AxiosResponse) => {
            //this.setState({detailsObj: res.data.result.user})
            setDetailsObj(res.data.result.user);
            updateLoader(false);
          })
          .catch((err: AxiosError) => {
            console.log("auth api error ::", err);
          });
        break;
      case DEN.SEARCH.TYPES.CLAN:
        clanApis
          .getPublicClans(data)
          .then((res: AxiosResponse) => {
            //this.setState({detailsObj: res.data.result[0]})
            setDetailsObj(res.data.result[0]);
            updateLoader(false);
          })
          .catch((err: AxiosError) => {
            console.log("clan api error ::", err);
          });
        break;
      case DEN.SEARCH.TYPES.ORG:
        organizationApis
          .getPublicOrganizations(data)
          .then((res: AxiosResponse) => {
            //console.log('API RES ::', res)
            //this.setState({detailsObj: res.data.result[0]})
            setDetailsObj(res.data.result[0]);
            updateLoader(false);
          })
          .catch((err: AxiosError) => {
            console.log("org api error ::", err);
          });
        break;
      default:
        authApis
          .getProfile(clanOrOrgId)
          .then((res: AxiosResponse) => {
            //this.setState({details: res.data.result[0]})
            setDetailsObj(res.data.result.user);
          })
          .catch((err: AxiosError) => {
            console.log("auth api error ::", err);
          });
        break;
    }
  }
};

export const getDenAchievementStats = () => {
  const denData = adminData?.den;

  let rankCriteriaData = denData.filter(
    (el) => el.type === "ACHIEVE_RANK_CRITERIA"
  );
  //console.log('rankCriteriaData ::', rankCriteriaData);

  let commentRankValues = [];
  COMMENTS_RANKS_ARRAY.map((el) =>
    commentRankValues.push(rankCriteriaData[0][el])
  );
  //console.log('commentRankValues ::', commentRankValues);

  let spotlightRankValues = [];
  SPOTLIGHT_RANKS_ARRAY.map((el) =>
    spotlightRankValues.push(rankCriteriaData[0][el])
  );
  //console.log('spotlightRankValues ::', spotlightRankValues);

  let denRankData = denData.filter((el) => el.type === "ACHIEVE_RANK_TITLE");
  //console.log('denRankData ::', denRankData);

  let rankCriteriaByType = {};
  denRankData.map((el, i) => {
    let type = el.subType;
    //console.log('type ::', type);
    let ranks = [];
    RANKS_ARRAY.map((val) => ranks.push(el[val]));
    //console.log('ranks', ranks)
    rankCriteriaByType[type] = ranks;
  });

  return {
    COMMENTS_RANK_VALUES: commentRankValues,
    SPOTLIGHT_RANK_VALUES: spotlightRankValues,
    RANK_CRITERIA_BY_TYPE: rankCriteriaByType,
  };
};
