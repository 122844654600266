import { Component } from 'react';
// Utils
import { getToken, profile } from './utils/localstorage.utils';
import { AxiosResponse, AxiosError } from "axios";
import Loader from './components/common/Loader';
import { Modal } from 'react-bootstrap';
import { ToastContainer, toast } from 'material-react-toastify';
import { errorHandler, getAdminSideData, getWebsiteData, saveLoader } from './utils/common.utils.js';
// Style
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'material-react-toastify/dist/ReactToastify.css';
// Services
import CommonApis from "./helper/common.api";
import ClanApis from "./helper/clan.api";
import AuthApis from './helper/auth.api.js';
// Components
import Navbar from './components/common/Navbar.js';
// Modals
import OurPolicies from './components/popup/OurPolicies';
import AcceptInvite from './components/popup/AcceptInvite';
import ConfirmationModal from './components/popup/ConfirmationModal';

// Routes
import AuthRoutes from './routes/AuthRoutes';
import UnAuthRoutes from './routes/UnAuthRoutes';
import { ERROR } from './utils/Strings.utils';
import { getNotifications, saveInvitationInNotifications, sendNotificationToMembers } from './utils/notification.utils';

class App extends Component {

  constructor() {
    super();
    this.state = {
      isAuthenticated: false,
      authUser: null,
      store: null,
      isActivateAccount: false,
      isNavigationIsActive: true,
      emailVerificationToken: null,
      createNewPasswordToken: null,
      joinClanId: null,
      joinTeamId: null,
      joinOrgId: null,
      joinClanOrTeamRole: null,
      joinTournamentId: null,
      stageId: null,
      matchId: null,
      gameId: null,
      groupIndex: null,
      participantType: null,
      isRoundRobin: false,
      viaNotification: false,
      isPaidTournament: false,
      isLoaderActive: 0,
      openLogin: false,
      openSignUp: false,
      openPolicies: false,
      acceptModalTitle: 'Accept Invite',
      parentUpdate: 0,
      discordProfile: null,
      confirm: {},
      connectToDiscord: false,
      isDataLoad: false
    };

    // API's Routes
    this.commonApi = new CommonApis();
    this.clanService = new ClanApis();
    this.api = new AuthApis();
  }

  componentDidMount() {
    const data = {
      msg: this.discordMsg(),
      key: ''
    }
    // Reading parameters for URI
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get('id');
    const error = queryParams.get('error');
    const emailVerificationToken = queryParams.get('emailVerificationToken');
    const createNewPasswordToken = queryParams.get('createNewPasswordToken');
    const joinClanId = queryParams.get('joinClanId');
    const joinTeamId = queryParams.get('joinTeamId');
    const joinOrgId = queryParams.get('joinOrgId');
    const joinClanOrTeamRole = queryParams.get('role');


    const joinTournamentId = queryParams.get('joinTournamentId');
    const stageId = queryParams.get('stageId');
    const matchId = queryParams.get('matchId');
    const gameId = queryParams.get('gameId');
    const groupIndex = queryParams.get('groupIndex');
    const participantType = queryParams.get('participantType');
    const isRoundRobin = queryParams.get('isRoundRobin');
    const isPaidTournament = queryParams.get('isPaidTournament');

    const viaNotification = queryParams.get('viaNotification');

    saveLoader(this.updateLoader);
    getWebsiteData()
    getAdminSideData();
    
    // console.log(`
    //   User ID : ${id}
    //   Error : ${error}
    //   Email Verification Token : ${emailVerificationToken}
    //   Create New Password Token : ${createNewPasswordToken}
    //   Clan ID : ${joinClanId}
    //   Team ID : ${joinTeamId}
    //   Org ID : ${joinOrgId}
    //   Role : ${joinClanOrTeamRole}
    //   Tournament ID : ${joinTournamentId}
    //   Stage ID : ${stageId}
    //   Match ID : ${matchId}
    //   Game Id : ${gameId}
    //   Group Index: ${groupIndex}
    //   Participant Type: ${participantType}
    //   Is Round Robin: ${isRoundRobin}
    //   Is Paid Tournament : ${isPaidTournament}
    // `);

    // Storing request parameters in state 
    this.setState({
      emailVerificationToken: emailVerificationToken,
      createNewPasswordToken: createNewPasswordToken,
      joinClanId: joinClanId,
      joinTeamId: joinTeamId,
      joinOrgId: joinOrgId,
      joinClanOrTeamRole: joinClanOrTeamRole,
      joinTournamentId: joinTournamentId,
      stageId: stageId,
      matchId: matchId,
      gameId: gameId,
      groupIndex: groupIndex,
      participantType: participantType,
      isRoundRobin: isRoundRobin,
      isPaidTournament: isPaidTournament,
      confirm: data,
      viaNotification: viaNotification
    });

    if (id !== null) {
      //window.history.pushState({}, document.title, "/");
      this.fetchAuthUser(id);
    }
    // else if (error === ERROR.DISCORD_ANOTHER_ACCOUNT) {
    //   toast.error(error);
    // }
    else if (error !== null) {
      this.props.history.push('/')
      // window.history.pushState({}, document.title, "/");
      toast.error(error);
    }
    else {
      this.storeCollector(joinClanId, joinTeamId, joinOrgId, joinTournamentId);
    }

    if (getToken()) {
      this.commonApi.getCurrentProfile()
        .then(
          (res: AxiosResponse) => {
            var data = res.data['result'];
            console.log("this.state.isAuthenticated - ",this.state.isAuthenticated)

            global.countryCode = data?.countryCode?.code ? data?.countryCode?.code : '+91'
            this.initiateLinkInvitation(data.id);
            this.setState({ discordProfile: res.data['result']?.discordProfile?.discordUsername, isDataLoad: true,isAuthenticated:true,authUser:{user: data} })
            // CHANGING LOGIN METHOD
            localStorage.setItem('login', JSON.stringify({
              login: true,
              token: getToken(),
              authUser: {
                user: data
              }
            }));
            console.log("global.countryCode app - ", global.countryCode)
          }
        ).catch(
          (err: AxiosError) => {
            this.setState({ isDataLoad: true })
            if (err?.response?.status === 401) {
              this.logout()
            }
            errorHandler(err);
          }
        )
    } else {
      this.setState({ isDataLoad: true })
    }
  }

  //Message display if discord not connected to account
  discordMsg = () => {
    return (
      <p className="fs-14 fw-600 text-center text-white">You haven't connected to discord,click yes to connect</p>
    )
  }

  storeCollector = (joinClanId, joinTeamId, joinOrgId, joinTournamentId) => {
    let store = JSON.parse(localStorage.getItem('login'));
    if (store && store.login) {
      this.setState({
        isAuthenticated: true,
        authUser: store.authUser
      });
    } else {
      if (joinClanId || joinTeamId || joinOrgId || joinTournamentId) {
        this.setState({
          openLogin: true
        });
        var msg;
        if (joinClanId) {
          msg = `In order to join this clan, You need to log in first!`;
        } else if (joinTeamId) {
          msg = `In order to join this team, You need to log in first!`;
        } else if (joinOrgId) {
          msg = `In order to join this organization, You need to log in first!`;
        } else {
          msg = `In order to join this tournament, You need to log in first!`;
        }
        toast.warning(msg);
      }
    }
  }

  initiateLinkInvitation = (userId) => {
    if(this.state.joinTeamId){
      saveInvitationInNotifications('team', { teamId : this.state.joinTeamId, role : this.state.joinClanOrTeamRole }, this.state.viaNotification);
    }

    if (this.state.joinClanId) {
      saveInvitationInNotifications('clan', { clanId : this.state.joinClanId, role : this.state.joinClanOrTeamRole }, this.state.viaNotification);
      this.joinClanOrTeam(userId, "clan");
    }else if(this.state.joinOrgId){
      saveInvitationInNotifications('organization', { orgId : this.state.joinOrgId, role : this.state.joinClanOrTeamRole }, this.state.viaNotification);
      this.joinClanOrTeam(userId, "organization");
    }else if (this.state.joinTournamentId) {
      console.log("===== this.props.location.search",this.props.location.search)
      saveInvitationInNotifications('tournament', { tournamentId : this.state.joinTournamentId, search : this.props.location.search }, this.state.viaNotification);
      
      if (profile()?.discordProfile?.discordUsername) {
        this.props.history.push(`/tournaments?joinTournamentId=${this.state.joinTournamentId}&stageId=${this.state.stageId}&matchId=${this.state.matchId}&gameId=${this.state.gameId}&groupIndex=${this.state.groupIndex}&participantType=${this.state.participantType}&isRoundRobin=${this.state.isRoundRobin}&isPaidTournament=${this.state.isPaidTournament}`);

      } else {

        this.setState({
          connectToDiscord: true
        });
      }

    }

  }


  joinClanOrTeam = (id, type) => {

    if (this.state.joinClanId || this.state.joinTeamId || this.state.joinOrgId) {

     
      var body = {
        clanId: this.state.joinClanId,
        teamId: this.state.joinTeamId,
        organizationId: this.state.joinOrgId,
        role: this.state.joinClanOrTeamRole,
        userId: id
      };

      this.updateLoader(true);
      this.clanService.joinClanOrTeamUsingInvitationLink(body, type)
        .then((response: AxiosResponse) => {
          this.updateLoader(false);
          // var link = this.state.joinClanId ?  "/clan/view/"+this.state.joinClanId : "/clan/team/view/"+this.state.joinTeamId;
          // this.props.history.push(link);  
          sendNotificationToMembers(type, body)
          this.setState({ parentUpdate: this.state.parentUpdate + 1 })
          this.redirectToView();
          this.clearInvitations();
          toast.success(response?.data?.message);
          // toast.success(`You have joined new ${type} successfully`);
        }).catch((reason: AxiosError) => {
          this.clearInvitations();
          this.updateLoader(false);
          errorHandler(reason);
        });
    }
  }

  redirectToView = () => {
    var link;
    console.log(`
    Clan ID : ${this.state.joinClanId}
    Team ID : ${this.state.joinTeamId}
    Org ID : ${this.state.joinOrgId}
    Tournament ID : ${this.state.joinTournamentId}
  `);
    if (this.state.joinClanId) {
      link = "/clan/view/" + this.state.joinClanId;
    } else if (this.state.joinTeamId) {
      link = "/clan/team/view/" + this.state.joinTeamId;
    } else if (this.state.joinOrgId) {
      link = "/organization/view/" + this.state.joinOrgId;
    } else {
      //link = "/organization/view/" + this.state.joinOrgId;
    }
    this.props.history.push(link);
  }

  clearInvitations = (el?) => {
    this.setState({
      joinClanId: null,
      joinTeamId: null,
      joinOrgId: null,
      joinClanOrTeamRole: null,
      joinTournamentId: null,
      stageId: null,
      matchId: null,
      gameId: null,
      groupIndex: null,
      participantType: null,
      isRoundRobin: false,
      connectToDiscord: false
    });
    this.removeParam("role");
    this.removeParam("joinClanId");
    this.removeParam("joinTeamId");
    this.removeParam("joinOrgId");
    this.removeParam("joinTournamentId");
    this.removeParam("stageId");
    this.removeParam("matchId");
    this.removeParam("gameId");
    this.removeParam("groupIndex");
    this.removeParam("participantType");
    this.removeParam("isRoundRobin");
    this.removeParam("isPaidTournament");
    this.removeParam("viaNotification");
  }

  removeParam(parameter) {
    var url = document.location.href;
    var urlParts = url.split('?');

    if (urlParts.length >= 2) {
      var urlBase = urlParts.shift();
      var queryString = urlParts.join("?");
      var prefix = encodeURIComponent(parameter) + '=';
      var pars = queryString.split(/[&;]/g);
      for (var i = pars.length; i-- > 0;)
        if (pars[i].lastIndexOf(prefix, 0) !== -1)
          pars.splice(i, 1);
      url = urlBase + '?' + pars.join('&');
      window.history.pushState('', document.title, url); // added this line to push the new url directly to url bar .

    }
    return url;
  }

  joinTeamAfterCreation = (id, role, modalTitle) => {
    this.setState({
      acceptModalTitle: modalTitle,
      joinTeamId: id,
      joinClanOrTeamRole: role
    });
  }

  fetchAuthUser = async (id) => {
    this.updateLoader(true);
    this.api.getProfile(id)
      .then((response: AxiosResponse) => {
        const result = response.data;
        localStorage.setItem('login', JSON.stringify({
          login: true,
          token: result.result.accessToken,
          authUser: result.result
        }));
        this.setState({
          isAuthenticated: true,
          authUser: result.result,
          discordProfile: result.result?.user?.discordProfile?.discordUsername ? result.result?.user?.discordProfile?.discordUsername : null
        });
        if (this.state.joinClanId || this.state.joinOrgId) {
          this.initiateLinkInvitation(id);
        }
        this.updateLoader(false);
      }).catch((reason: AxiosError) => {
        this.updateLoader(false);
        errorHandler(reason);
      });

  };

  saveToLocalStorage = (result, isAccountActivated) => {
    localStorage.setItem('login', JSON.stringify({
      login: true,
      token: result.accessToken,
      authUser: result
    }));
    this.setState({
      isAuthenticated: true,
      authUser: result,
      isActivateAccount: isAccountActivated,
      discordProfile: result?.user?.discordProfile?.discordUsername ? result?.user?.discordProfile?.discordUsername : null
    });
    this.initiateLinkInvitation(result.user.id);
  }


  //Logout and clear local storage
  logout = (isOpenLogin?) => {
    localStorage.clear();
    this.setState({
      isAuthenticated: false,
      authUser: null
    });
    if (isOpenLogin) {
      this.props.history.push('/')
      this.openLogin("openLogin");
      return false
    }
    if (this.state.joinTeamId) {
      this.openLogin("openLogin");
      window.history.pushState({}, document.title, `/?joinTeamId=${this.state.joinTeamId}&role=${this.state.joinClanOrTeamRole}`);
    } else {
      this.props.history.push('/home')
    }
  }

  closeActivateAccount = () => {
    this.setState({
      isActivateAccount: false
    });
  }

  showOrHideNav = (param) => {
    this.setState({
      isNavigationIsActive: param
    })
  }

  backToHome = () => {
    this.setState({
      createNewPasswordToken: null
    });
    window.history.pushState({}, document.title, "/");
  }

  //Set loader for whole project
  updateLoader = (param) => {
    var count = (param === true ? (this.state.isLoaderActive + 1) : (this.state.isLoaderActive - 1));
    count = ((count < 0) ? 0 : count);
    // console.log("count :: ",count)
    this.setState({
      isLoaderActive: count
    })
  }

  resetLoginStatus = () => {
    this.setState({
      openLogin: false,
      openSignUp: false,
    });
  }

  openPolicies = (param) => {
    this.setState({
      openPolicies: param
    });
  }

  openLogin = (key) => {
    this.setState({
      [key]: true
    })
  }

  confirmed = () => {
    this.clearInvitations();
    this.props.history.push('/profile')
  }


  render() {

    const newPasswordToken = this.state.createNewPasswordToken;
    const openAcceptTeamInvitationForm = ((this.state.joinTeamId) && this.state.isAuthenticated);

    return (
      <div className="App">

        {/* To display loader while fetching data from backend */}
        {((this.state.isLoaderActive > 0) || !this.state.isDataLoad) ? <div><Loader /> </div> : null}

        {/* To check if navigation bar required or not for that page */}
        {(this.state.isNavigationIsActive && !this.state.emailVerificationToken) ?
          <Navbar state={this.state} isAuthenticated={this.state.isAuthenticated} authUser={this.state.authUser} logout={this.logout} saveToLocalStorage={this.saveToLocalStorage} backToHome={this.backToHome} openLogin={this.state.openLogin} openSignUp={this.state.openSignUp} newPasswordToken={newPasswordToken} resetLoginStatus={() => this.resetLoginStatus()} updateLoader={this.updateLoader} /> : null}

        {/* Routes of application */}
        {
          this.state.isDataLoad ?
            <div className="content">
              {this.state.isAuthenticated ?
                <AuthRoutes state={this.state} isAuthenticated={this.state.isAuthenticated} clearInvitations={this.clearInvitations} storeCollector={this.storeCollector} joinTeamAfterCreation={this.joinTeamAfterCreation} discordProfile={this.state.discordProfile} logout={this.logout} showOrHideNav={this.showOrHideNav} updateLoader={this.updateLoader} openPolicies={this.openPolicies} openLogin={this.openLogin} parentUpdate={this.state.parentUpdate} />
                :
                <UnAuthRoutes state={this.state} isAuthenticated={this.state.isAuthenticated} saveToLocalStorage={this.saveToLocalStorage} showOrHideNav={this.showOrHideNav} updateLoader={this.updateLoader} openLogin={this.openLogin} />
              }
            </div> : null
        }


        {/* To display Success, Warning or Error messages to user */}
        <div className="toast-message" >
          <ToastContainer />
        </div>

        {/* To ask user to verify his email first to perform any action */}
        <Modal show={this.state.openPolicies} centered dialogClassName="our-policies-modal">
          <Modal.Body>
            <OurPolicies authUser={this.state.authUser ? this.state.authUser.user : null} openPolicies={this.openPolicies} updateLoader={this.updateLoader} />
          </Modal.Body>
        </Modal>

        {/* Accept invitation form for team to join */}
        <Modal show={openAcceptTeamInvitationForm && this.state.discordProfile} centered dialogClassName="accept-invite-modal">
          <Modal.Body>
            <AcceptInvite
              clearInvitations={this.clearInvitations}
              joinTeamId={this.state.joinTeamId}
              joinClanOrTeam={this.joinClanOrTeam}
              joinTeamRole={this.state.joinClanOrTeamRole}
              close={this.clearInvitations}
              type="add"
              info={{}}
              title={this.state.acceptModalTitle}
              role={this.state.joinClanOrTeamRole}
              isTournamentInvitation={false}
            />
          </Modal.Body>
        </Modal>

        {/* Ask user to link with Discord Account before initiating the invitation to join team or tournament  */}
        <Modal show={(openAcceptTeamInvitationForm && !this.state.discordProfile) || this.state.connectToDiscord} centered dialogClassName="accept-invite-modal">
          <Modal.Body>
            <ConfirmationModal confirm={this.state.confirm}
              onClickOpenConfirm={this.clearInvitations} confirmed={this.confirmed} />
          </Modal.Body>
        </Modal>

      </div>
    );
  }
}

export default App;
