import React, { Component } from "react";
import { AiOutlineClose } from "react-icons/ai";
import {
  POST_INTERACTIONS,
  SPIN_WHEEL_ASSETS,
} from "../../../../utils/Images.utils";
import { capitalize } from "../../../../utils/common.utils";
// import WheelComponent from "react-wheel-of-prizes/dist/index.modern";
import {
  updateDenProfileInLocalStorage,
  updateLastSpinDetails,
} from "../../../../utils/Den.utils";

/**
 * @author
 * @class DailySpinWheel
 **/

const segments = SPIN_WHEEL_ASSETS.PRIZES;
const segColors = SPIN_WHEEL_ASSETS.BG_COLORS;

class DailySpinWheel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSpinWheel: true,
      spinPrize: null,
      denProfile: props.denProfile,
    };
  }

  componentWillReceiveProps(newProps) {
    this.setState({
      denProfile: newProps.denProfile,
    });
  }

  onFinished = (winner) => {
    setTimeout(() => {
      this.setState({ showSpinWheel: false, spinPrize: winner.toUpperCase() });
      this.updateLastSpinDetails(winner.toUpperCase());
    }, 1000);
  };

  updateLastSpinDetails = (key) => {
    var data = {
      [SPIN_WHEEL_ASSETS.PRIZE_ASSETS[key].NAME.toLowerCase()]:
        SPIN_WHEEL_ASSETS.PRIZE_ASSETS[key].QUANTITY,
    };
    updateLastSpinDetails(
      this.state.denProfile?._id,
      data,
      this.updateProfileDetails
    );
  };

  updateProfileDetails = (updatedProfileDetails) => {
    const updatedDen = { ...this.state.denProfile, ...updatedProfileDetails };
    setTimeout(() => {
      //updateDenProfileInLocalStorage(updatedDen);
      this.props.updateState("denProfile", updatedDen);
      this.setState({
        denProfile: updatedDen,
      });
    }, 7000);
  };

  render() {
    //console.log('Spin Prize ::', this.state.spinPrize);
    return (
      <div className="den-spin-wheel-container my-2 p-2">
        <div className="d-flex justify-content-between">
          <p className="fs-16 fs-lg-20 fw-bold my-0">Spin the wheel...</p>
          <AiOutlineClose
            className="cursor-pointer"
            onClick={() => this.props.updateState("isSpinnedToday", true)}
          />
        </div>
        <p className="fs-12 fs-lg-16 fw-500 my-2">
          Get exciting interactions when you spin the wheel and you can use it
          to interact with the den posts!!!
        </p>
        <div className="row">
          <div className="col-lg-6 col-md-6 col-12">
            <p className="fs-16 fs-lg-20 fw-700">Your collections</p>
            <div className="row">
              {Object.keys(POST_INTERACTIONS.SPARKS).map((key, i) => {
                return (
                  <div
                    className="col-4 d-flex flex-column justify-content-center align-items-center my-2"
                    key={i}
                  >
                    <img
                      src={POST_INTERACTIONS.SPARKS[key].BONUS}
                      alt=""
                      style={{ height: "35px" }}
                    />
                    <span className="fs-12 fw-600">
                      {capitalize(POST_INTERACTIONS.SPARKS[key].NAME)}
                    </span>
                    <span className="fs-12 fw-600">
                      {
                        this.state.denProfile?.sparks[
                          POST_INTERACTIONS.SPARKS[key].NAME
                        ]
                      }
                    </span>
                  </div>
                );
              })}
              <div className="col-4 d-flex flex-column justify-content-center align-items-center my-2">
                <img
                  src={POST_INTERACTIONS.HAMMER.BONUS}
                  alt=""
                  style={{ height: "35px" }}
                />
                <span className="fs-12 fw-600">Hammer</span>
                <span className="fs-12 fw-600">
                  {this.state.denProfile?.hammer}
                </span>
              </div>
            </div>
          </div>
          {/* <hr className='vertical-hr' style={{height:'100%'}} /> */}
          <div className="col-lg-6 col-md-6 col-12">
            {this.state.showSpinWheel ? (
              // <WheelComponent
              //     segments={segments}
              //     segColors={segColors}
              //     onFinished={winner => this.onFinished(winner)}
              //     primaryColor="black"
              //     contrastColor="white"
              //     buttonText="Spin"
              //     isOnlyOnce={false}
              //     size={190}
              //     upDuration={500}
              //     downDuration={600}
              //     fontFamily="Arial"
              // />
              <h2>Currently Unavailable</h2>
            ) : (
              <div className="d-flex flex-column justify-content-center align-items-center w-100 ">
                <h4 className="fs-20 fw-700 blink_me">Congratulations !!!</h4>
                <p className="fs-12 fs-lg-14 fw-500" style={{ width: "80%" }}>
                  You have received{" "}
                  {
                    SPIN_WHEEL_ASSETS.PRIZE_ASSETS[this.state.spinPrize]
                      .QUANTITY
                  }{" "}
                  {`${
                    SPIN_WHEEL_ASSETS.PRIZE_ASSETS[this.state.spinPrize].NAME
                  }`}
                  (s), which has been deposited into your inventory successfully
                </p>
                <img
                  src={
                    SPIN_WHEEL_ASSETS.PRIZE_ASSETS[this.state.spinPrize].LOGO
                  }
                  alt=""
                  className="blink_me"
                  style={{ width: "70px", height: "auto" }}
                />
                <p className="fs-20 fw-700 blink_me">
                  +
                  {
                    SPIN_WHEEL_ASSETS.PRIZE_ASSETS[this.state.spinPrize]
                      .QUANTITY
                  }
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default DailySpinWheel;
